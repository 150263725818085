import React from 'react';
import '../App.css';
import logger from './logger';

const RedirectButton = ({ redirectLink, redirectMessage, onRedirect }) => {
  logger.log('RedirectButton props:', redirectLink, redirectMessage);

  const handleClick = () => {
    onRedirect();
    window.open(redirectLink, '_blank');
  };

  return (
    <button className="redirect-button" onClick={handleClick}>
      {redirectMessage || "Thank you for voting! Check out our website"}
    </button>
  );
};

export default RedirectButton;
