import React, { useEffect } from 'react';
import logger from './logger';


const PaymentSuccess = () => {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const sessionId = params.get('session_id');

        if (sessionId) {
            fetch('/api/payments/payment-success', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ session_id: sessionId }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.message === 'Subscription updated successfully') {
                    // Handle successful update (e.g., notify the user, redirect, etc.)
                    logger.log(data.user);
                } else {
                    logger.error('Failed to update subscription:', data.message);
                }
            })
            .catch(error => {
                logger.error('Error during subscription update:', error);
            });
        }
    }, []);

    return (
        <div>
            <h2>Payment Successful!</h2>
            <p>Your subscription has been updated.</p>
        </div>
    );
};

export default PaymentSuccess;
