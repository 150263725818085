import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import '../App.css';
import logger from './logger';


const ResetPassword = ({ onPasswordReset, onClose }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    fetch(`/auth/reset-password/${token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ newPassword }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          setMessage(data.message);
          setTimeout(() => {
            window.location.href = '/'; // Redirect to home page after a short delay
          }, 2000); // 2-second delay to show the success message
        } else {
          setError(data.error || 'Password reset failed');
        }
      })
      .catch(error => {
        logger.error('Error resetting password:', error);
        setError('Password reset failed');
      });
  };

  return (
    <div className="Modal-overlay">
      <div className="Modal-content">
        <button className="modal-close" onClick={onClose}>×</button>
        <h2>Reset Password</h2>
        {message && <p className="Auth-message">{message}</p>}
        {error && <p className="Auth-error">{error}</p>}
        <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="Auth-input"
            required
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="Auth-input"
            required
          />
          <button type="submit" className="Auth-button">Reset Password</button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
