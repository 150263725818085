import React from 'react';
import '../App.css'; // Make sure to import the CSS file
import logger from './logger';


const SubmitButton = ({ onClick }) => (
  <button
    className="submit-button"
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '10px 20px',
      backgroundColor: '#333', // Same grey as the backdrop card
      color: 'white', // White text
      border: '2px solid white',
      borderRadius: '5px',
      cursor: 'pointer',
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: '14px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow to the button
    }}
    onClick={onClick}
    onMouseDown={(e) => e.stopPropagation()} // Prevent mousedown event from propagating
    onTouchStart={(e) => e.stopPropagation()} // Prevent touchstart event from propagating
    onTouchEnd={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }} // Handle touchend event
  >
    Submit
  </button>
);

export default SubmitButton;
