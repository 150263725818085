import React, { useRef, useEffect, useState } from 'react';
import '../App.css';

const ConfidenceBar = ({
  label = '', // Accept the label prop
  confidencePercentages = [],
  confidenceLabels = [], // Include confidenceLabels prop with default
  className = '',
  optionPercentage = '0.0', // New prop for percentage
  compressionFactor = 1,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const barRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [tooltip, setTooltip] = useState({
    visible: false,
    content: '',
    position: { top: 0, left: 0 },
  });
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting);
        });
      },
      {
        rootMargin: '0px',
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );

    if (barRef.current) {
      setHeight(barRef.current.offsetHeight);
      observer.observe(barRef.current);
    }

    return () => {
      if (barRef.current) {
        observer.unobserve(barRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (barRef.current) {
      setHeight(barRef.current.offsetHeight);
    }
  }, [isVisible]);

  const totalVotes = confidencePercentages.reduce((acc, segment) => acc + segment.count, 0);

  // Filter out zero percentage segments and handle cases with no confidence levels
  const filteredConfidencePercentages = confidencePercentages.filter(
    (segment) => segment.percentage > 0
  );

  if (filteredConfidencePercentages.length === 0) {
    // No confidence levels; display a single segment representing the total percentage
    return (
      <div className={`confidence-bar-wrapper ${className}`}>
        <h1 className="confidence-label">{label}</h1>
        <div className="option-percentage">{optionPercentage}%</div>
        <div className="confidence-bar-container">
          <div
            className="confidence-segment"
            style={{
              backgroundColor: confidencePercentages[0]?.color || 'gray',
              width: '100%',
            }}
          />
        </div>
      </div>
    );
  }

  const handleMouseDown = (event) => {
    setIsDragging(true);
    handleSegmentInteraction(event);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      handleSegmentInteraction(event);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setTooltip({ visible: false, content: '', position: { top: 0, left: 0 } });
  };

  const handleTouchStart = (event) => {
    setIsDragging(true);
    handleSegmentInteraction(event.touches[0]);
  };

  const handleTouchMove = (event) => {
    if (isDragging) {
      handleSegmentInteraction(event.touches[0]);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    setTooltip({ visible: false, content: '', position: { top: 0, left: 0 } });
  };

  const handleSegmentInteraction = (event) => {
    const barRect = barRef.current.getBoundingClientRect();
    const clientX = event.clientX || event.touches[0].clientX;
    const relativeX = clientX - barRect.left;

    let accumulatedWidth = 0;
    let segmentIndex = -1;

    for (let i = 0; i < filteredConfidencePercentages.length; i++) {
      accumulatedWidth += (filteredConfidencePercentages[i].percentage / 100) * barRect.width;
      if (relativeX <= accumulatedWidth) {
        segmentIndex = i;
        break;
      }
    }

    const segment = filteredConfidencePercentages[segmentIndex];

    if (segment) {
      const top = event.clientY - barRect.top - 60; // Position tooltip above the segment
      const left = event.clientX - barRect.left - 20; // Center tooltip horizontally on the click

      const adjustedVoteCount = (segment.count * compressionFactor).toLocaleString(); // Adjust and format the vote count
      const voteText =
        compressionFactor > 1
          ? `≈ ${adjustedVoteCount} votes` // Add the "≈" symbol if compression factor is greater than 1
          : adjustedVoteCount === '1'
          ? '1 vote'
          : `${adjustedVoteCount} votes`;

      setTooltip({
        visible: true,
        content: `<div class="tooltip-opinion">${segment.label || label}</div><div class="tooltip-timestamp">${voteText}</div>`,
        position: { top, left },
      });
    }
  };

  return (
    <div
      ref={barRef}
      className={`confidence-bar-wrapper ${className} ${isVisible ? 'visible' : ''}`}
      style={{ minHeight: `${height}px`, position: 'relative' }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {isVisible ? (
        <>
          <div className="tooltip-wrapper">
            {tooltip.visible && (
              <div
                className="tooltip"
                style={{
                  top: `${tooltip.position.top}px`,
                  left: `${tooltip.position.left}px`,
                  zIndex: 1000,
                }}
                dangerouslySetInnerHTML={{ __html: tooltip.content }}
              />
            )}
          </div>
          <h1 className="confidence-label">{label}</h1>
          <div className="option-percentage">{optionPercentage}%</div>
          <div className="confidence-bar-container">
  {confidenceLabels.length === 0 ? (
    // No confidence levels: render a single segment taking the full width
    <div
      className="confidence-segment"
      style={{
        backgroundColor: confidencePercentages[0]?.color || 'gray',
        width: '100%',
      }}
    />
  ) : (
    // With confidence levels: render multiple segments
    filteredConfidencePercentages.map((segment, index) => (
      <div
        key={index}
        className="confidence-segment"
        style={{
          backgroundColor: segment.color,
          width: `${segment.percentage}%`,
        }}
      />
    ))
  )}
</div>
          {confidenceLabels && confidenceLabels.length > 0 && (
  <div
    className={`confidence-vote-counts ${
      filteredConfidencePercentages.length === 1 ? 'single' : ''
    }`}
  >
    {filteredConfidencePercentages.map((segment, index) => (
      <div key={index} className="confidence-vote-count">
        <div>{`${segment.percentage}%`}</div>
        <div>{segment.label}</div>
      </div>
    ))}
  </div>
)}
        </>
      ) : (
        <div style={{ height: `${height}px` }} />
      )}
    </div>
  );
};

export default ConfidenceBar;
