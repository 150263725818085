import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import logger from './logger';

const QRCodeGeneratorDashboard = ({ pollId, hash }) => {

  return (
    <div style={{ 
      display: 'inline-grid'
    }}>
      <QRCodeCanvas
  id={`qr-code-canvas-${pollId}`}
  value={`${window.location.origin}/poll/${hash}`}
  size={300} // Adjust size for better uniformity
  bgColor={"#ffffff"}
  fgColor={"#202021"}
  level={"L"}
  includeMargin={false}
  imageSettings={{
    src: require("./qr-icon.png"),
    x: undefined,
    y: undefined,
    height: 60, // Adjust image height for a balanced look
    width: 60, // Adjust image width
    excavate: true,
  }}
  style={{
    height: '200px',
    width: '200px',
    marginTop: '15px',
    borderRadius: '15px',
    padding: '15px',
    boxSizing: 'content-box',
    background: 'white',
    marginBottom: '10px'
  }}
/>
    </div>
  );
};

export default QRCodeGeneratorDashboard;