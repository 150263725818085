import React, { useState } from 'react';
import './About.css';
import DesktopBG from '../assets/bg.png';
import MobileBG from '../assets/bg.png';
import ScrollingPollsVertical from './ScrollingPollsVertical';
import ScrollingPollsVerticalReverse from './ScrollingPollsVerticalReverse';
import { useNavigate, Link } from 'react-router-dom';



import Logo from '../assets/logo.png';
import HeroImg from '../assets/Hero.png';
import YouTubeIcon from '../assets/youtube.png';
import FacebookIcon from '../assets/facebook.png';
import InstagramIcon from '../assets/instagram.png';
import LinkedInIcon from '../assets/linkedin.png';
import PinterestIcon from '../assets/pinterest.png';
import RedditIcon from '../assets/reddit.png';
import SnapchatIcon from '../assets/snapchat.png';
import TikTokIcon from '../assets/tiktok.png';
import XIcon from '../assets/x.png';
import OpinionsImg from '../assets/opinions.png';
import FlexibilityImg from '../assets/flexibility.png';
import HandledImg from '../assets/handled.png';

const About = ({ isLoggedIn }) => {
    const isDesktop = window.innerWidth >= 768;

    const backgroundStyle = {
      backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
    };

    const navigate = useNavigate();

    const goToSignUp = () => {
      navigate('/signup');
    };
    const goToDashboard = () => {
      navigate('/dashboard/main');
    };
    const goToFeed = () => {
        navigate('/feed');
      };

    return (
      
        <div className="about-page-wrapper">
                <div style={backgroundStyle}></div>
            <div className="about-container">
                <div className="combined-sections-container">
                <section className="about-para-section">
  <div className="text-content">
    <h2>About</h2>
    <p>
      <strong>OPINARI</strong> | <em>oh-pin-r-ee</em> | [verb]  
      The Latin word meaning “to be of the opinion” refers to the act of forming or holding an opinion or belief about something.  
      <br /><br />
      <strong>OPINARI</strong> lets Influencers profit from sending super-relevant, tightly segmented, and highly engaged audiences directly to advertisers from any social post or online content.  
      <br /><br />
      <strong>OPINARI</strong> gathers and displays real-time opinions generated by a simple, patent-pending, thumb-driven color wheel that encourages fast and accurate answering.  
      <br /><br />
      <strong>OPINARI</strong> lets anyone, like an Influencer, create a sophisticated poll easily and quickly by simply typing any question into our AI-assisted platform.  
      <br /><br />
      <strong>OPINARI</strong> democratizes the process of conducting polls or surveys because it’s free and open to anyone who may not have the resources to run traditional polling campaigns.  
      <br /><br />
      <strong>OPINARI</strong> lets brands piggyback on the trust of Influencers to sell much more via pay-per-click (PPC) advertising.  
      <br /><br />
      <strong>OPINARI</strong> polls can be shared universally via a single link or QR-Code, making them accessible to anyone, anywhere.  
      <br /><br />
      <strong>OPINARI</strong> welcomes quality content-oriented Influencers and Creators to join for free and start profiting from their influence in an entirely new way.  
      <br /><br />
      <button
          onClick={isLoggedIn ? goToDashboard : goToSignUp}
          className="cta-button"
        >
          {isLoggedIn ? 'Go to Dashboard' : 'Start Free'}
        </button>    
    </p>
  </div>
</section>

  

 
  




                    
                </div>
            </div>

            <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo-section">
            <img src={Logo} alt="Logo" className="footer-logo" />
          </div>

          <div className="footer-links">
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/contact">Contact</a></li>
              <li><a href="/FAQs">FAQs</a></li>
            </ul>
          </div>

          <div className="footer-socials">
            <img src={YouTubeIcon} alt="YouTube" />
            <img src={FacebookIcon} alt="Facebook" />
            <img src={InstagramIcon} alt="Instagram" />
            <img src={LinkedInIcon} alt="LinkedIn" />
            <img src={TikTokIcon} alt="TikTok" />
          </div>
        </div>

        <div className="footer-bottom">
          <p>&copy; 2024 OPINARI. All rights reserved.</p>
        </div>
      </footer>
        </div>
    );
};

export default About;