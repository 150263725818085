import React from 'react';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import logger from './logger';

// Function to generate a random vibrant color
const getRandomVibrantColor = () => {
  const colors = [
    '#FF5A5F', // Red
    '#FFAA5A', // Orange
    '#FFD15C', // Yellow
    '#8CE071', // Green
    '#00D1C1', // Turquoise
    '#007A87', // Blue
    '#7B0051', // Purple
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

const ProfileIcon = ({ firstName, size, profileImageUrl, showCameraIcon }) => {
  if (!firstName && !profileImageUrl) {
    return null;
  }

  const initial = firstName ? firstName.charAt(0).toUpperCase() : '';
  const backgroundColor = getRandomVibrantColor();

  return (
    <div
      className="profile-icon"
      style={{
        width: size,
        height: size,
        backgroundColor: profileImageUrl ? 'transparent' : backgroundColor,
        color: '#fff',
        fontSize: size / 2.5, // Adjusted to fit better inside the circle
        fontWeight: '400',
        borderRadius: '50%',
        position: 'relative',
        backgroundImage: profileImageUrl ? `url(${profileImageUrl})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',            // Flexbox for centering
        alignItems: 'center',       // Center vertically
        justifyContent: 'center',   // Center horizontally
        textAlign: 'center',
      }}
    >
      {!profileImageUrl && initial}
      {showCameraIcon && (
        <FontAwesomeIcon
          icon={faCamera}
          style={{
            position: 'absolute',
            bottom: '5px',
            right: '5px',
            color: '#fff',
            fontSize: size / 4,
          }}
        />
      )}
    </div>
  );
};

export default ProfileIcon;
