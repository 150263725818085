// useFitText.js
import { useState, useEffect, useRef } from 'react';

const useFitText = (maxFontSize, minFontSize = 12, deps = []) => {
  const ref = useRef(null);
  const [fontSize, setFontSize] = useState(maxFontSize);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const resizeText = () => {
      const parentWidth = element.parentElement.offsetWidth;
      let newFontSize = maxFontSize;

      element.style.fontSize = `${newFontSize}px`;

      while (element.scrollWidth > parentWidth && newFontSize > minFontSize) {
        newFontSize -= 1;
        element.style.fontSize = `${newFontSize}px`;
      }

      setFontSize(newFontSize);
    };

    resizeText();

    window.addEventListener('resize', resizeText);
    return () => {
      window.removeEventListener('resize', resizeText);
    };
  }, [maxFontSize, minFontSize, ref, ...deps]); // Include 'opinion' in dependencies

  return { ref, fontSize };
};

export default useFitText;
