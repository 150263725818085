import React, { useState, useEffect, useRef } from 'react';
import './Info.css'; // Import the updated CSS file for styling
import logger from './logger';


const Info = ({ infoCase }) => {
  const [isOpen, setIsOpen] = useState(false); // State to manage the visibility of the popup
  const popupRef = useRef(null); // Ref to reference the popup element

  // Function to toggle the popup visibility
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  // Function to close the popup when clicking outside
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Effect to add event listener for clicks
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup event listener when the component unmounts or when isOpen changes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Function to determine the info text based on the infoCase prop
  const getInfoText = () => {
    switch (infoCase) {
      case 'Category':
        return (
            <>
              <b>Category:</b> Your poll will fall under this category on the home page.
            </>
          );      case 'Voting Rules':
        return (
          <>
            <b>One Vote Per Person:</b> Prevents the same user from voting multiple times.<br /><br />
            <b>Multiple Votes Per Person:</b> Allows the same user to vote multiple times.
          </>
        );
      case 'Visibility':
        return (
            <>
              <b>Public:</b> Your poll will be visible to anyone.<br /><br />
              <b>Private:</b> Your poll will only be visible to people you share it with.<br /><br />
              <b>Private + reCaptcha:</b> Users will have to complete a verification step before they can vote.
            </>
          );
      case 'Redirect':
        return (
            <>
              <b>URL:</b> This is the URL you want to redirect users to after they vote.<br /><br />
              <b>Message:</b> This is the message that will be displayed on the redirect button.
            </>
          );
      case 'Schedule':
        return (
            <>
              <b>Schedule:</b> Users can only vote between the dates you set, and a countdown will be displayed.
            </>
          );       case 'Live Stats':
        return 'View live statistics and updates for this poll.';
      default:
        return 'No information available for this case.';
    }
  };

  return (
    <div className="info-container" ref={popupRef}>
      {/* Circular info icon with active state */}
      <div
        className={`info-icon ${isOpen ? 'active' : ''}`}
        onClick={togglePopup}
      >
        𝒊
      </div>
      {/* Popup for displaying information */}
      {isOpen && (
        <div className="info-popup">
          {getInfoText()}
        </div>
      )}
    </div>
  );
};

export default Info;
