import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignUpPage.css'; // Reusing SignUpPage styles for consistency
import logo from '../assets/logo.png';

const LoginPage = ({ onLogin }) => {
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ emailOrUsername, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.token) {
          onLogin(data.token, data.userId, data.username);
          navigate('/dashboard/main');
        } else {
          setError(data.error || 'Login failed');
        }
      })
      .catch((error) => {
        console.error('Error logging in:', error);
        setError('Login failed');
      });
  };

  return (
    <div className="signup-page__container">
      <div className="signup-page__form-section">
        <div className="signup-page__form-wrapper">
        <img src={logo} alt="Logo" onClick={() => navigate('/')} />
          <h2>Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="signup-page__form-group">
              <label className="signup-page__label">Email or Username</label>
              <input
                type="text"
                placeholder="Enter your email or username"
                value={emailOrUsername}
                onChange={(e) => setEmailOrUsername(e.target.value)}
                required
                className="signup-page__input"
              />
            </div>
            <div className="signup-page__form-group">
              <label className="signup-page__label">Password</label>
              <input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="signup-page__input"
              />
            </div>
            {error && <p className="signup-page__error-message">{error}</p>}
            <button type="submit" className="signup-page__custom-submit-button">
              Login
            </button>
          </form>
          <div className="signup-page__form-footer">
  <p className="signup-page__custom-signin-link">
    <button onClick={() => navigate('/initiate-reset-password')} className="signup-page__link-button">
      Forgot Password?
    </button>
  </p>
  <p className="signup-page__custom-signin-link">
    Don't have an account yet? <a href="/signup">Sign up</a>
  </p>
</div>

        </div>
      </div>
      <div className="signup-page__image-section">
        <img src="../assets/bg-placeholder.png" alt="Login illustration" /> {/* Placeholder illustration */}
      </div>
    </div>
  );
};

export default LoginPage;
