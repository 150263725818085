import React, { useRef, useEffect, useState } from 'react';
import '../App.css';
import { hsvaToHslaString } from '@uiw/color-convert';
import logger from './logger';
import './ColorBarHomepage.css';


const ColorBarHomepage = ({
  colorPercentages = [],
  counts = {},
  labels = [],
  showConfidenceLabels = false,
  className = '',
  compressionFactor = 1,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const barRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [tooltip, setTooltip] = useState({
    visible: false,
    content: '',
    position: { top: 0, left: 0 },
  });
  const [isDragging, setIsDragging] = useState(false);
  const threshold = 200;
  const [currentSegmentCount, setCurrentSegmentCount] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting);
        });
      },
      {
        rootMargin: '0px',
        threshold: 0.01, // Trigger when 1% of the element is visible
      }
    );

    if (barRef.current) {
      setHeight(barRef.current.offsetHeight);
      observer.observe(barRef.current);
    }

    return () => {
      if (barRef.current) {
        observer.unobserve(barRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (barRef.current) {
      setHeight(barRef.current.offsetHeight);
    }
  }, [isVisible]);

  const totalVotes = colorPercentages.length;

  const getPercentage = (count) =>
    totalVotes > 0 ? ((count / totalVotes) * 100).toFixed(1) : 0;

  const handleMouseDown = (event) => {
    setIsDragging(true);
    handleSegmentInteraction(event);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      handleSegmentInteraction(event);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setTooltip({ visible: false, content: '', position: { top: 0, left: 0 } });
  };

  const handleTouchStart = (event) => {
    setIsDragging(true);
    handleSegmentInteraction(event.touches[0]);
  };

  const handleTouchMove = (event) => {
    if (isDragging) {
      handleSegmentInteraction(event.touches[0]);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    setTooltip({ visible: false, content: '', position: { top: 0, left: 0 } });
  };

  const handleSegmentInteraction = (event) => {
    const barRect = barRef.current.getBoundingClientRect();
    const segmentIndex = Math.floor(
      ((event.clientX - barRect.left) / barRect.width) * currentSegmentCount
    );
    const segment = groupedColorPercentages[segmentIndex];

    if (segment) {
      const top = event.clientY - barRect.top - 60; // Position tooltip above the segment
      const left = event.clientX - barRect.left - 20; // Center tooltip horizontally on the click

      setTooltip({
        visible: true,
        content: `<div class="color-bar-homepage__tooltip-opinion">${segment.opinion}</div><div class="color-bar-homepage__tooltip-timestamp">${new Date(
          segment.timestamp
        ).toLocaleString()}</div>`,
        position: { top, left },
      });
    }
  };

  const groupVotes = (votes) => {
    if (votes.length <= threshold) {
      return votes;
    }

    let factor = 2;
    while (votes.length / factor > threshold) {
      factor *= 2;
    }

    return votes.filter((_, index) => index % factor === 0);
  };

  const groupedColorPercentages = groupVotes(colorPercentages);

  useEffect(() => {
    if (groupedColorPercentages.length !== currentSegmentCount) {
      setCurrentSegmentCount(groupedColorPercentages.length);
    }
  }, [groupedColorPercentages, currentSegmentCount]);

  return (
    <div
      ref={barRef}
      className={`color-bar-homepage__wrapper ${className} ${isVisible ? 'visible' : ''}`}
      style={{ minHeight: `${height}px`, position: 'relative' }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {isVisible ? (
        <>
          <div className="color-bar-homepage__tooltip-wrapper">
            {tooltip.visible && (
              <div
                className="color-bar-homepage__tooltip"
                style={{
                  top: `${tooltip.position.top}px`,
                  left: `${tooltip.position.left}px`,
                  zIndex: 1000,
                }}
                dangerouslySetInnerHTML={{ __html: tooltip.content }}
              />
            )}
          </div>
          <div className="color-bar-homepage__container">
            {totalVotes === 0 ? (
              <div className="color-bar-homepage__segment" style={{ backgroundColor: '#5d5d5d', width: '100%' }} />
            ) : (
              Array.isArray(groupedColorPercentages) &&
              groupedColorPercentages.map((segment, index) => (
                <div
                  key={index}
                  className="color-bar-homepage__segment"
                  style={{
                    backgroundColor: segment.color,
                    width: '100%',
                  }}
                />
              ))
            )}
          </div>
          <div className="color-bar-homepage__vote-counts">
            {counts.disagree > 0 && (
              <div className="color-bar-homepage__vote-count">
                <div>{`${getPercentage(counts.disagree)}%`}</div>
                <div>{labels[2]}</div>
              </div>
            )}
            {counts.neutral > 0 && (
              <div className="color-bar-homepage__vote-count">
                <div>{`${getPercentage(counts.neutral)}%`}</div>
                <div>{labels[1]}</div>
              </div>
            )}
            {counts.agree > 0 && (
              <div className="color-bar-homepage__vote-count">
                <div>{`${getPercentage(counts.agree)}%`}</div>
                <div>{labels[0]}</div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div style={{ height: `${height}px` }} />
      )}
    </div>
  );
};

export default ColorBarHomepage;