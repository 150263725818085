import React, { useState, useEffect } from 'react';
import Poll from './Poll'; // Import the Poll component
import PollSuccessModal from './PollSuccessModal'; // Import PollSuccessModal

const MyPolls = ({ 
    userId, 
    username, 
    userEmail, 
    isLoggedIn, 
    isProfileView, 
    pollSuccessData, 
    onCloseSuccessModal 
}) => {
    const [polls, setPolls] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    console.log('MyPolls Component Props:', {
        userId,
        username,
        userEmail,
        isLoggedIn,
        isProfileView,
    });

    // Show the success modal if pollSuccessData is provided
    useEffect(() => {
        if (pollSuccessData) {
            setShowSuccessModal(true);
        }
    }, [pollSuccessData]);

    // Fetch user's polls
    useEffect(() => {
        if (userId) {
            fetch(`/polls?creator=${userId}`)
                .then(response => response.json())
                .then(data => {
                    if (data.polls) {
                        setPolls(data.polls);
                        console.log('Fetched user polls:', data.polls);
                    } else {
                        console.error('No polls found:', data);
                    }
                })
                .catch(error => console.error('Error fetching user polls:', error));
        }
    }, [userId]);

    const handleDeletePoll = (pollId) => {
        setPolls(polls.filter(poll => poll._id !== pollId));
    };

    const closeModal = () => {
        setShowSuccessModal(false);
        onCloseSuccessModal(); // Reset modal state in the parent component
    };

    return (
        <div className="Root-grid-container">
            {showSuccessModal && pollSuccessData && (
    <PollSuccessModal
        pollLink={pollSuccessData.pollLink}  // Full URL now passed
        isTemporary={pollSuccessData.isTemporary}
        onClose={closeModal}
    />
)}

            {polls.length > 0 ? (
                polls.map((poll) => (
                    <div key={poll._id} className="Poll-item">
                        <Poll
                            pollId={poll._id}
                            question={poll.question}
                            labels={poll.labels}
                            logo={poll.logo || "/logo-poll.png"}
                            logoFill={poll.logoFill}
                            votes={poll.votes}
                            hash={poll.hash}
                            creatorUsername={poll.creatorUsername}
                            creatorId={poll.creator}
                            isLoggedIn={isLoggedIn}
                            userId={userId}
                            username={username}
                            onDelete={() => handleDeletePoll(poll._id)}
                            isProfileView={true}
                            voteAuthenticationMethod={poll.voteAuthenticationMethod}
                            confidenceLabels={poll.confidenceLabels}
                            imageAction={poll.imageAction}
                            roundedCorners={poll.roundedCorners}
                            startDate={poll.startDate}
                            endDate={poll.endDate}
                            scheduledPoll={poll.scheduledPoll}
                            userEmail={userEmail}
                            totalVotes={poll.totalVotes}
                            isTemporary={poll.isTemporary}
                            isLegacyPoll={poll.isLegacyPoll}
                        />
                    </div>
                ))
            ) : (
                <p>No polls available. Create a new poll to get started.</p>
            )}
        </div>
    );
};

export default MyPolls;
