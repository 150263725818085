import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Wheel from '@uiw/react-color-wheel';
import { hsvaToHex, hsvaToHslaString } from '@uiw/color-convert';
import SubmitButton from './SubmitButton';
import RedirectButton from './RedirectButton'; // Import the new component
import ColorBar from './ColorBar';
import ConfidenceBar from './ConfidenceBar';
import { Player } from '@lottiefiles/react-lottie-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareFromSquare, faChartBar, faCog, faWrench, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faShareSquare } from '@fortawesome/free-regular-svg-icons';
import animationData from '../animations/vote-animation.json';
import clickAnimationData from '../animations/TapAnimation.json';
import PollAreaChart from './PollAreaChart';
import '../App.css';
import QRCodeGenerator from './QRCodeGenerator';
import EmbedCodeGenerator from './EmbedCodeGenerator';
import DropdownMenu from './DropdownMenu';
import ReportPollModal from './ReportPollModal';
import UpgradeModal from './UpgradeModal'; // Import the UpgradeModal component
import ImageModal from './ImageModal'; // Import the new ImageModal component
import ReCAPTCHA from 'react-google-recaptcha';
import EmbedWidgetGenerator from './EmbedWidgetGenerator';
import PdfImage from '../assets/pdf-download.png'; // Make sure to import your logo
import { jwtDecode } from 'jwt-decode'; // Fixed import
import logger from './logger';
import Lottie from 'lottie-react';
import PollShareModal from './PollShareModal'; // Import the new PollShareModal component
import useFitText from './useFitText'; // Make sure the hook is imported
import Advertisements from './Advertisements'; // Import the new ImageModal component



















const Poll = ({ pollId, question, category, labels, logo, logoFill, hash, isLoggedIn, userId, creatorId, creatorUsername, username, isProfileView, onDelete, voteAuthenticationMethod, confidenceLabels = [], onVoteSubmit, imageAction, roundedCorners, startDate, endDate, scheduledPoll, pollActive, isConnectedPoll, useCaptcha,   userEmail, totalVotes, isTemporary, scrollToBottom, isLegacyPoll, isAdvertising

}) => {
  const [hsva, setHsva] = useState({ h: 0, s: 0, v: 100, a: 1 });
  const [size, setSize] = useState(400);
  const [opinion, setOpinion] = useState({ baseOpinion: "What's your Opinion?", strength: '' });
  const [votes, setVotes] = useState([]);
  const [showSubmit, setShowSubmit] = useState(false);
  const [wheelActive, setWheelActive] = useState(false);
  const [animationVisible, setAnimationVisible] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showFinalConfirmDelete, setShowFinalConfirmDelete] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [reportPath, setReportPath] = useState('');
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [showCreateReport, setShowCreateReport] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [showEmbedCode, setShowEmbedCode] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');
  const [redirectMessage, setRedirectMessage] = useState('');
  const [showRedirectButton, setShowRedirectButton] = useState(false);
  const [alreadyVoted, setAlreadyVoted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [showLiveStats, setShowLiveStats] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showDownloadCSV, setShowDownloadCSV] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Aggregating data...');
  const [userVotedPolls, setUserVotedPolls] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [settingsVoteAuthenticationMethod, setSettingsVoteAuthenticationMethod] = useState(1); // Default value
  const [settingsVisibility, setSettingsVisibility] = useState('Public'); // Default value
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentPollActive, setCurrentPollActive] = useState(pollActive);
  const [remainingTime, setRemainingTime] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false); // State to track CAPTCHA verification
  const recaptchaRef = useRef(null);
  const [isPollNotStarted, setIsPollNotStarted] = useState(false);
  const [isPollEnded, setIsPollEnded] = useState(false);
  const [pollIsInactive, setPollIsInactive] = useState(false);
  const [overlayText, setOverlayText] = useState('');
  const [optimisticTotalVotes, setOptimisticTotalVotes] = useState(totalVotes || 0);
  const [compressionFactor, setCompressionFactor] = useState(1); // Add state for compressionFactor
  const [showEmbedWidget, setShowEmbedWidget] = useState(false);
  const [showInitialAnimation, setShowInitialAnimation] = useState(true); // New state for the initial animation
  const [showShareModal, setShowShareModal] = useState(false);
  const [isPollInTemporaryCreations, setIsPollInTemporaryCreations] = useState(false);
  const { ref, fontSize } = useFitText(24, 12, [opinion]);
  const [showAdvertisements, setShowAdvertisements] = useState(false);
  const [votedOpinion, setVotedOpinion] = useState("");
  const [city, setCity] = useState("");






  useEffect(() => {
    console.log('Category:', category);
  }, [category]);




  logger.log('Animation data for initial animation:', clickAnimationData);

  useEffect(() => {
    if (isTemporary) {
      const temporaryCreations = JSON.parse(localStorage.getItem('temporaryCreations')) || [];
      const pollExists = temporaryCreations.some(poll => poll.pollId === pollId);
      setIsPollInTemporaryCreations(pollExists);
    }
  }, [isTemporary, pollId]);


  const claimPoll = async () => {
    try {
      const requestBody = {
        userId: userId,           // Pass the userId from the component's props
        creatorUsername: username // Pass the username from the component's props
      };
  
      const response = await fetch(`/polls/${pollId}/claim`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Ensure the user is logged in
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody) // Send the userId and creatorUsername in the body
      });
  
      if (!response.ok) {
        throw new Error('Failed to claim poll');
      }
  
      const data = await response.json();
      console.log('Poll successfully claimed!', data);
      
      // Navigate to the /profile page after claiming
      navigate('/profile');
    } catch (error) {
      console.error('Error claiming poll:', error);
      alert('An error occurred while claiming the poll.');
    }
  };


  const handleClaimPoll = () => {
    if (!isLoggedIn) {
      // Navigate to the sign-up page and pass the pollId in the state
      navigate('/signup', { state: { pollId } });
    } else {
      // Regular claim logic for logged-in users
      claimPoll();
    }
  };
  
  

  useEffect(() => {
    // Check if the animation has been shown globally in local storage
    const animationShown = localStorage.getItem('animationShown');
  
    // Check if the user has voted on any poll in local storage
    const hasVotedInCache = Object.keys(localStorage).some((key) => key.startsWith('voted_'));
  
    // Determine if the animation should be hidden
    const shouldHideAnimation = animationShown || hasVotedInCache || isLoggedIn;
  
    // Set the state based on the combined conditions
    setShowInitialAnimation(!shouldHideAnimation);
  }, [isLoggedIn]);


  const handleDownload = () => {
    window.open("https://opinari-images.nyc3.cdn.digitaloceanspaces.com/static/report-sample.pdf", "_blank");
  };

  const handleCaptchaSuccess = (token) => {
    setCaptchaVerified(true);
    verifyCaptcha(token); // Send token to your server for verification
  };

  const verifyCaptcha = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/verify-captcha`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
  
      const data = await response.json();
      if (data.success) {
        logger.log('CAPTCHA verified successfully');
        setCaptchaVerified(true); // You can set the verified state here if successful
      } else {
        logger.error('CAPTCHA verification failed');
        setCaptchaVerified(false);
      }
    } catch (error) {
      logger.error('Error verifying CAPTCHA:', error);
      setCaptchaVerified(false);
    }
  };
  
  

  // Function to handle CAPTCHA expiration
  const handleCaptchaExpire = () => {
    setCaptchaVerified(false);
  };

  const handleShareClick = () => {
    setShowShareModal(true); // Show the custom share modal
  };


  useEffect(() => {
    if (!scheduledPoll) {
      if (!currentPollActive) {
        setCurrentPollActive(true);
      }
      return; // Exit the effect early if the poll is not scheduled
    }
  
    const pollNotStarted = startDate && new Date(startDate) > currentDate;
    const pollEnded = endDate && new Date(endDate) < currentDate;
    const pollInactive = pollNotStarted || pollEnded;
  
    setIsPollNotStarted(pollNotStarted);
    setIsPollEnded(pollEnded);
    setPollIsInactive(pollInactive);
  
    const text = pollNotStarted
      ? [`Voting starts on`, new Date(startDate).toLocaleString()]
      : pollEnded
      ? [`Voting ended on`, new Date(endDate).toLocaleString()]
      : [];
    setOverlayText(text);
  
    const intervalId = setInterval(() => {
      const now = new Date();
      setCurrentDate(now);
  
      if (pollNotStarted) {
        const timeDifference = new Date(startDate) - now;
  
        if (timeDifference > 0) {
          const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
          const months = Math.floor((timeDifference % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
          const days = Math.floor((timeDifference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  
          const parts = [];
          if (years > 0) parts.push(`${years}y`);
          if (months > 0) parts.push(`${months}mo`);
          if (days > 0) parts.push(`${days}d`);
          if (hours > 0) parts.push(`${hours}h`);
          if (minutes > 0) parts.push(`${minutes}m`);
          if (seconds > 0) parts.push(`${seconds}s`);
  
          setRemainingTime(parts.join(' '));
        } else {
          clearInterval(intervalId);
          setRemainingTime('');
          setCurrentPollActive(true);
          setIsPollNotStarted(false);
          setPollIsInactive(false);
        }
      } else {
        setRemainingTime(''); // Clear countdown if the poll is active or ended
      }
    }, 1000);
  
    return () => clearInterval(intervalId);
  }, [startDate, endDate, scheduledPoll, currentPollActive, pollId, currentDate]);


  useEffect(() => {
    const pollNotStarted = startDate && new Date(startDate) > currentDate;
    const pollEnded = endDate && new Date(endDate) < currentDate;
    const pollInactive = pollNotStarted || pollEnded;
    const isPollActive = !pollInactive; // Poll is active if it's not inactive
  
    if (currentPollActive !== isPollActive) {
      setCurrentPollActive(isPollActive);
  
      // Commenting out the function and call to update the poll active state
      /*
      const updatePollActiveState = async (newPollActiveState) => {
        try {
          const response = await fetch(`${apiUrl}/polls/${pollId}/update-active`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ pollActive: newPollActiveState })
          });
  
          if (!response.ok) {
            throw new Error('Failed to update poll active state');
          }
  
          const data = await response.json();
          logger.log('Poll active state updated:', data.message);
        } catch (error) {
          logger.error('Error updating poll active state:', error);
        }
      };
  
      updatePollActiveState(isPollActive);
      */
    }
  }, [currentPollActive, startDate, endDate, scheduledPoll, currentDate, pollId]);
  
  

  

  useEffect(() => {
    if (scheduledPoll) {
      if (isPollNotStarted) {
        logger.log(`Poll ${pollId} has not started yet. It will start on: ${new Date(startDate).toLocaleString()}`);
      } else if (isPollEnded) {
        logger.log(`Poll ${pollId} has ended. It ended on: ${new Date(endDate).toLocaleString()}`);
      } else {
        logger.log(`Poll ${pollId} is currently active.`);
      }
    }
  }, [scheduledPoll, isPollNotStarted, isPollEnded, startDate, endDate, pollId]);
  
  

  
  const DEFAULT_LOGO_URL = '/logo-poll.png';

  <script src="https://kit.fontawesome.com/e9fba19b92.js" crossorigin="anonymous"></script>




  const containerRef = useRef(null);
  const colorWheelRef = useRef(null);
  let impressionTimer = useRef(null);
  const dropdownRef = useRef(null);


  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080';



  useEffect(() => {
    const fetchVotes = async () => {
      try {
        let votesUrl = `${apiUrl}/polls/${pollId}/votes`; // Default to regular votes
  
        // Check if the poll has 1000 or more votes
        if (totalVotes >= 1000) {
          // Try to fetch compressed votes
          const compressedResponse = await fetch(`${apiUrl}/compressed-votes/${pollId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          const compressedData = await compressedResponse.json();
  
          // If compressed votes exist, use them, otherwise, fallback to regular votes
          if (compressedData && compressedData.votes) {
            setVotes(compressedData.votes);
            setCompressionFactor(compressedData.compressionFactor || 1); // Set the compression factor
            return; // Exit early since we've successfully set the votes
          }
        }
  
        // If the poll has less than 1000 votes or no compressed votes were found
        const response = await fetch(votesUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
  
        if (Array.isArray(data)) {
          setVotes(data);
        } else {
          logger.error('Fetched data is not an array:', data);
        }
      } catch (error) {
        logger.error('Error fetching votes:', error);
      }
    };
  
    fetchVotes();
  
    // Fetch votedPolls for logged-in user
    if (isLoggedIn) {
      fetch(`${apiUrl}/users/${userId}/voted-polls`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data.votedPolls)) {
          setUserVotedPolls(data.votedPolls);
        } else {
          logger.error('Fetched votedPolls is not an array:', data);
        }
      })
      .catch(error => logger.error('Error fetching votedPolls:', error));
    }
  }, [pollId, apiUrl, userId, isLoggedIn, totalVotes]);
  
  

  useEffect(() => {
    const fetchPollSettings = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/poll-settings/${pollId}`);
        const data = await response.json();
        
        if (response.ok) {
          setSettingsVisibility(data.visibility);
          setSettingsVoteAuthenticationMethod(data.voteAuthenticationMethod);
        } else {
          logger.error('Failed to fetch poll settings:', data.message);
        }
      } catch (err) {
        logger.error('Error fetching poll settings:', err);
      }
    };
  
    fetchPollSettings();
  }, [pollId, apiUrl]);
  
  

  useEffect(() => {
    if (isLoggedIn && userVotedPolls.includes(pollId)) {
      setAlreadyVoted(voteAuthenticationMethod === 1); // Only set `alreadyVoted` to true if the poll is locked to one vote
    }
  }, [userVotedPolls, pollId, isLoggedIn, voteAuthenticationMethod]);

  const handleChange = (color) => {
    if (wheelActive) {
      setHsva(color.hsva);
      setBodyBackgroundColor(hsvaToHex(color.hsva));
      const opinionData = mapColorToOpinion(color.hsva);
      setOpinion(opinionData); // Update with object
      setShowRedirectButton(false); // Hide redirect button when the wheel is interacted with
    }
  };

   // report submission
const handleReportSubmit = async (reportText) => {
  try {
    const response = await fetch(`${apiUrl}/report-poll`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ pollId, reportText, pollHash: hash }) // Include poll hash in the request body
    });

    if (!response.ok) {
      throw new Error('Failed to submit report');
    }

  } catch (error) {
    logger.error('Error submitting report:', error);
    alert('Failed to submit report');
  }
};

const checkSubscriptionStatus = async (userId) => {
  try {
    const response = await fetch(`/auth/subscription-status/${userId}`);
    if (!response.ok) throw new Error('Failed to fetch subscription status');
    const data = await response.json();
    return data.subscriptionStatus;
  } catch (error) {
    logger.error('Error fetching subscription status:', error);
    return null;
  }
};



const handleDownloadCSVClick = async () => {
  const subscriptionStatus = await checkSubscriptionStatus(userId);
  if (!['Pro', 'Elite', 'Unlimited'].includes(subscriptionStatus)) {
    setShowUpgradeModal(true);
    return;
  }

  const csvRows = [
    ['Vote Choice', 'Vote Hue', 'Vote Saturation', 'Vote Confidence', 'Gender', 'Date', 'Time', 'Country'],
    ...votes.map(vote => {
      const voteDate = new Date(vote.createdAt);
      const date = voteDate.toLocaleDateString(); // Extract date
      const time = voteDate.toLocaleTimeString(); // Extract time
      return [
        vote.choiceLabel,
        vote.choice,
        vote.saturation,
        vote.confidence,
        vote.gender,
        date,
        time,
        vote.country
      ];
    })
  ];

  const csvContent = csvRows.map(e => e.join(",")).join("\n");
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const downloadUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  const sanitizedQuestion = question.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  link.download = `${sanitizedQuestion}-votes.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(downloadUrl);
};


const getRotationOffset = (numOptions) => {
  switch (numOptions) {
    case 2:
      return 180;
    case 3:
      return 208;
    case 4:
      return 222;
    case 5:
      return 210;
    case 6:
      return 565;
    case 7:
      return 540;
    case 8:
      return 550;
    case 9:
      return 550;
    case 10:
      return 555;
    case 11:
      return 555;
    case 12:
      return 555;
    default:
      return 0;
  }
};

  




const mapColorToOpinion = (hsva) => {
  const { h, s } = hsva;
  if (s === 0) return { baseOpinion: 'No Opinion', strength: '' }; // Return as an object

  let baseOpinion;
  let confidenceIndex;
  let strength;
  let opinionConfidenceLabels;

  if (labels.length === 3 && isLegacyPoll) {
    // Legacy poll logic remains unchanged
    if (h >= 60 && h <= 180) {
      baseOpinion = labels[0];
    } else if (h > 300 || h <= 60) {
      baseOpinion = labels[2];
    } else if (h > 180 && h <= 300) {
      baseOpinion = labels[1];
    } else {
      baseOpinion = 'Mixed Feelings';
    }

    const opinionIndex = labels.indexOf(baseOpinion);

    if (
      confidenceLabels &&
      confidenceLabels[opinionIndex] &&
      confidenceLabels[opinionIndex].length > 0
    ) {
      opinionConfidenceLabels = confidenceLabels[opinionIndex];
      const numConfidenceLevels = opinionConfidenceLabels.length;

      const stepSize = 100 / numConfidenceLevels;
      confidenceIndex = Math.min(
        Math.floor(s / stepSize),
        numConfidenceLevels - 1
      );
      strength = opinionConfidenceLabels[confidenceIndex];
      return { baseOpinion, strength }; // Return both parts as an object
    } else {
      return { baseOpinion, strength: '' }; // No confidence level, just return base opinion
    }
  } else {
    // Adjusted non-legacy poll logic
    const numOpinions = labels.length;
    const segmentSize = 360 / numOpinions;
    const initialOffset = -segmentSize / 2;

    // Use the getRotationOffset function for proper rotation
    const rotationOffset = getRotationOffset(numOpinions);

    // Adjust the hue to account for rotation and offset
    const adjustedHue = (h + rotationOffset - initialOffset + 360) % 360;
    const optionIndex = Math.floor(adjustedHue / segmentSize) % numOpinions;
    baseOpinion = labels[optionIndex];

    if (
      confidenceLabels &&
      confidenceLabels[optionIndex] &&
      confidenceLabels[optionIndex].length > 0
    ) {
      opinionConfidenceLabels = confidenceLabels[optionIndex];
      const numConfidenceLevels = opinionConfidenceLabels.length;

      const stepSize = 100 / numConfidenceLevels;
      confidenceIndex = Math.min(
        Math.floor(s / stepSize),
        numConfidenceLevels - 1
      );
      strength = opinionConfidenceLabels[confidenceIndex];
      return { baseOpinion, strength }; // Return both parts as an object
    } else {
      return { baseOpinion, strength: '' }; // No confidence level, just return base opinion
    }
  }
};











  const detectAndReplaceLinks = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="poll-link">
          view link
        </a>
      ) : (
        part
      )
    );
  
    return (
      <>
        &quot;
        {parts}
        &quot;
      </>
    );
  };
  
  
  
  

  const handleMouseUp = useCallback((event) => {
    if (wheelActive && colorWheelRef.current && colorWheelRef.current.contains(event.target)) {
      setShowSubmit(true);
    }
  }, [wheelActive]);

  const handleTouchEnd = useCallback((event) => {
    if (wheelActive && colorWheelRef.current && colorWheelRef.current.contains(event.target)) {
      setShowSubmit(true);
    }
  }, [wheelActive]);



    // Function to check if the token is expired
    const isTokenExpired = (token) => {
      if (!token) return true;
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      return decodedToken.exp < currentTime;
    };

  
  
    const handleSubmit = async () => {
      logger.log('Vote submit process started');
      setIsSubmitting(true);
      setWheelActive(false);
    
      // Determine base opinion
      let baseOpinion = 'No Opinion';
      const h = hsva.h;
      const s = hsva.s;
    
      if (s === 0) {
        baseOpinion = 'No Opinion';
      } else {
        if (h >= 60 && h <= 180) {
          baseOpinion = labels[0];
        } else if (h > 300 || h <= 60) {
          baseOpinion = labels[2];
        } else if (h > 180 && h <= 300) {
          baseOpinion = labels[1];
        }
      }
    
      // Get the corresponding confidence labels array based on the determined opinion
      const opinionIndex = labels.indexOf(baseOpinion);
      const opinionConfidenceLabels = confidenceLabels[opinionIndex] || ['Very Low', 'Low', 'Medium', 'High', 'Very High'];
      const numConfidenceLevels = opinionConfidenceLabels.length;
    
      // Dynamically calculate the saturation step for each confidence level
      const stepSize = 100 / numConfidenceLevels;
    
      // Find the appropriate confidence level based on saturation
      const confidenceIndex = Math.min(Math.floor(s / stepSize), numConfidenceLevels - 1);
    
      const newVote = {
        pollId,
        choice: hsva.h,
        confidence: confidenceIndex,
        saturation: hsva.s,
        voterId: isLoggedIn ? userId : null,
        createdAt: new Date().toISOString(),
      };

      // Set the voted opinion for displaying ads
      setVotedOpinion(opinion);
    
      logger.log('New vote object created:', newVote);
    
      // Optimistic UI update
      setVotes((prevVotes) => [...prevVotes, newVote]);
      setBodyBackgroundColorWithFade('', 0.5);
    
      // Update the optimisticTotalVotes count optimistically
      setOptimisticTotalVotes((prevTotalVotes) => prevTotalVotes + 1);
    
      setShowSubmit(false);
      setAnimationVisible(true);
    
      if (!isLoggedIn && scrollToBottom) {
        scrollToBottom();
      }
    
      logger.log('UI updated optimistically');
    
      const refreshAccessToken = async () => {
        try {
          const response = await fetch('/auth/refresh-token', { method: 'POST', credentials: 'include' });
          if (!response.ok) throw new Error('Failed to refresh token');
          const { accessToken } = await response.json();
          localStorage.setItem('accessToken', accessToken);
          const decodedToken = jwtDecode(accessToken);
          logger.log('New access token obtained:', accessToken);
          logger.log('New token expires at:', new Date(decodedToken.exp * 1000).toISOString());
          return accessToken;
        } catch (error) {
          logger.error('Error refreshing access token:', error);
          throw error;
        }
      };
    
      const submitVote = async (token) => {
        try {
          logger.log('Submitting vote to server...');
          const response = await fetch(`${apiUrl}/polls/${pollId}/vote`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(newVote),
          });
    
          if (!response.ok) throw new Error('Failed to submit vote');
          const data = await response.json();
          logger.log('Vote successfully submitted:', data);

          // Update state with city data
          if (data.city) {
            setCity(data.city); // Add a state variable for the city
          }
          
          if (voteAuthenticationMethod === 1) {
            localStorage.setItem(`voted_${pollId}`, true);
            localStorage.setItem(`choice_${pollId}`, JSON.stringify(newVote));
          }
    
          if (onVoteSubmit) onVoteSubmit();
    
          const onAnimationComplete = () => {
            logger.log('Animation complete');
            setAnimationVisible(false);
            setIsSubmitting(false);
    
            if (isAdvertising) {
              logger.log('Switching to advertisements');
              
              setShowAdvertisements(true); // Switch to advertisements
            } else {
              logger.log('Setting post-animation UI');
              setAlreadyVoted(true);
    
              if (data.redirectLink) {
                setRedirectLink(data.redirectLink);
                setRedirectMessage(data.redirectMessage);
                setShowRedirectButton(true);
              } else {
                setShowSubmit(false);
              }
            }
          };
    
          // Reduce the delay if ads are enabled
          const animationDuration = isAdvertising ? 1000 : 1600; // 500ms for ads, 1600ms otherwise
          setTimeout(onAnimationComplete, animationDuration);        } catch (error) {
          logger.error('Error submitting vote:', error);
          throw error;
        }
      };
    
      try {
        let token = localStorage.getItem('accessToken');
        if (isTokenExpired(token)) {
          logger.log('Refreshing token...');
          token = await refreshAccessToken();
        }
        await submitVote(token);
      } catch (error) {
        logger.error('Error during vote submission:', error);
        if (error.message.includes('Invalid or expired token')) {
          try {
            const newToken = await refreshAccessToken();
            await submitVote(newToken);
          } catch (refreshError) {
            logger.error('Failed to refresh token:', refreshError);
            setVotes((prevVotes) => prevVotes.filter((vote) => vote !== newVote));
            setOptimisticTotalVotes((prevTotalVotes) => prevTotalVotes - 1);
            setAnimationVisible(false);
            setIsSubmitting(false);
          }
        } else {
          setVotes((prevVotes) => prevVotes.filter((vote) => vote !== newVote));
          setOptimisticTotalVotes((prevTotalVotes) => prevTotalVotes - 1);
          setAnimationVisible(false);
          setIsSubmitting(false);
        }
      }
    
      localStorage.setItem('animationShown', 'true');
    };
    
  
  



  
  
  
  
  
  
  
  const handleSaveSettings = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/update-poll-settings/${pollId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          visibility: settingsVisibility,
          voteAuthenticationMethod: settingsVoteAuthenticationMethod,
        }),
      });
  
      if (response.ok) {
        alert('Settings updated successfully! Refresh your poll to see the changes.');
        setShowSaveButton(false); // Hide the save button after successful update
      } else {
        const data = await response.json();
        logger.error('Failed to save settings:', data.message);
        alert('Failed to save settings.');
      }
    } catch (err) {
      logger.error('Error saving settings:', err);
      alert('Error saving settings.');
    }
  };


  
  const generateConfidenceColors = () => {
    const numOpinions = labels.length;
    const colors = {};
  
    if (numOpinions <= 3) {
      // Old logic for 2 or 3 options
      const segmentSize = 360 / numOpinions;
      const initialOffset = 120;
  
      labels.forEach((label, index) => {
        const baseHue = (index * segmentSize + initialOffset) % 360;
        colors[label] = { h: baseHue, s: 100, v: 100, a: 1 };
      });
    } else {
      // New logic for more than 3 options
      const segmentSize = 360 / numOpinions;
      const initialOffset = -segmentSize / 2;
      const rotationOffset = getRotationOffset(numOpinions);
  
      labels.forEach((label, index) => {
        // Center of each segment
        const adjustedHue = (index * segmentSize + segmentSize / 2 + 360) % 360;
        // Reverse calculation to get base hue
        const baseHue = (adjustedHue - rotationOffset + initialOffset + 360) % 360;
        colors[label] = { h: baseHue, s: 100, v: 100, a: 1 };
      });
    }
  
    return colors;
  };
  
  
  
  
  
  
  
  const confidenceColors = generateConfidenceColors();



  
    
  
  
  
  


  const handleActivateWheel = (e) => {
    if (voteAuthenticationMethod === 1) {
      const voted = localStorage.getItem(`voted_${pollId}`);
      if (isLoggedIn && alreadyVoted) {
        setWheelActive(false);
      } else if (!wheelActive && colorWheelRef.current && colorWheelRef.current.contains(e.target) && !voted && !isSubmitting) {
        setWheelActive(true);
        setShowInitialAnimation(false); // Hide the initial animation
      } else if (voted) {
        setAlreadyVoted(true);
        setWheelActive(false);
      }
    } else {
      if (!wheelActive && colorWheelRef.current && colorWheelRef.current.contains(e.target) && !isSubmitting) {
        setWheelActive(true);
        setShowRedirectButton(false);
        setAlreadyVoted(false);
        setShowInitialAnimation(false); // Hide the initial animation
      }
    }
  };
  
  
  
  
  
  const handleDeleteClick = () => {
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = () => {
    setShowFinalConfirmDelete(true);
  };

  const handleFinalConfirmDelete = () => {
    fetch(`${apiUrl}/polls/${pollId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to delete poll');
      }
      onDelete(pollId);
    })
    .catch(error => {
      logger.error('Error deleting poll:', error);
    });
  };

  const handleAnalyticsClick = () => {
    setShowAnalytics(prevShowAnalytics => !prevShowAnalytics);
  };

  const handleCreateReportClick = async () => {
    // Check subscription status at the very beginning
    const subscriptionStatus = await checkSubscriptionStatus(userId); // Pass userId to the function
  
    if (!['Pro', 'Elite', 'Unlimited'].includes(subscriptionStatus)) {
      setShowUpgradeModal(true); // Show the upgrade modal
      return;
    }
  
    setIsGeneratingReport(true);
    setLoadingProgress(0);
    setLoadingMessage('Aggregating data...');
  
    // Define the steps for the progress bar and messages
    const loadingSteps = [
      { progress: 25, duration: 13000, message: 'Aggregating data...' },
      { progress: 50, duration: 28000, message: 'Generating AI Insights...' },
      { progress: 90, duration: 22000, message: 'Rendering Charts...' },
    ];
  
    // Function to animate progress bar
    const animateProgressBar = async (steps, index = 0) => {
      if (index >= steps.length) {
        setLoadingMessage('Finishing up...');
        return;
      }
  
      const { progress, duration, message } = steps[index];
      setLoadingMessage(message);
  
      // Animate the progress
      const startProgress = loadingProgress;
      const increment = (progress - startProgress) / (duration / 100); // Increment per 100ms
  
      const interval = setInterval(() => {
        setLoadingProgress((prev) => {
          const newProgress = prev + increment;
          if (newProgress >= progress) {
            clearInterval(interval);
            setLoadingProgress(progress);
            setTimeout(() => animateProgressBar(steps, index + 1), 1000); // Pause for 1 second before next step
            return progress;
          }
          return newProgress;
        });
      }, 100);
    };
  
    // Start animating the progress bar
    await animateProgressBar(loadingSteps);
  
    // Prepare data for the report
    const totalVotes = votes.length;
    const colorPercentages = calculateColorPercentages(votes);
    const confidencePercentages = [
      { label: labels[0], percentages: calculateConfidencePercentages(votes, labels[0], confidenceColors[labels[0]]) },
      { label: labels[1], percentages: calculateConfidencePercentages(votes, labels[1], confidenceColors[labels[1]]) },
      { label: labels[2], percentages: calculateConfidencePercentages(votes, labels[2], confidenceColors[labels[2]]) }
    ];
  
    const data = {
      pollId: pollId, // Include the pollId here
      question: question,
      date: new Date().toISOString().slice(0, 10),
      username: username,
      userEmail: userEmail,  // Add the user's email here
      choices: labels.map(label => ({
        name: label,
        votes: votes.filter(vote => classifyColor(vote.choice) === label).length,
        percentage: totalVotes ? ((votes.filter(vote => classifyColor(vote.choice) === label).length / totalVotes) * 100).toFixed(2) : "0.00"
      })),
      colorPercentages: colorPercentages,
      counts: {
        agree: votes.filter(vote => classifyColor(vote.choice) === labels[0]).length,
        disagree: votes.filter(vote => classifyColor(vote.choice) === labels[2]).length,
        neutral: votes.filter(vote => classifyColor(vote.choice) === labels[1]).length
      },
      labels: labels,
      confidencePercentages: confidencePercentages,
      votes: votes, // Ensure votes are included here
      confidenceLabels: confidenceLabels // Add confidenceLabels here
    };

    // Log the data before sending the request
    logger.log('Data sent to PDF generation server:', data);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_PDF_GENERATION_URL}/generate-pdf`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });
      if (!response.ok) throw new Error('Failed to generate report');
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'Poll_Report.pdf';
      link.style.display = 'none'; // Make sure the link is not visible
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      logger.error('Error generating report:', error);
    } finally {
      setLoadingProgress(100);
      setLoadingMessage('Finishing up...');
      setTimeout(() => {
        setIsGeneratingReport(false);
        setLoadingMessage('Aggregating data...');
        setLoadingProgress(0);
      }, 500);
    }
  };

  
  
  
  
  
  
  
  


const handleRedirect = () => {
  setShowRedirectButton(false);
  setWheelActive(false);  // Deactivate the wheel
  setShowSubmit(false);   // Ensure the submit button is hidden
};

  

useEffect(() => {
  const handleResize = () => {
    const pollItem = document.querySelector('.Poll-item');
    if (pollItem) {
      const pollWidth = pollItem.offsetWidth;
      const newSize = Math.min(pollWidth, window.innerHeight) * 0.83;
      setSize(newSize); // This assumes setSize is managing the color wheel size
    }
  };

  window.addEventListener('resize', handleResize);
  window.addEventListener('mouseup', handleMouseUp);
  window.addEventListener('touchend', handleTouchEnd);
  handleResize();

  // Check local storage for poll ID and choice if voteAuthenticationMethod is 1
  if (voteAuthenticationMethod === 1) {
    const voted = localStorage.getItem(`voted_${pollId}`);
    const storedChoice = localStorage.getItem(`choice_${pollId}`);
    if (voted && storedChoice) {
      const choiceData = JSON.parse(storedChoice);
      setHsva({ h: choiceData.choice, s: choiceData.saturation, v: 100, a: 1 });
      setOpinion(mapColorToOpinion({ h: choiceData.choice, s: choiceData.saturation, v: 100, a: 1 }));
      setWheelActive(false);
      setAlreadyVoted(true);
    }
  }

  return () => {
    window.removeEventListener('resize', handleResize);
    window.removeEventListener('mouseup', handleMouseUp);
    window.removeEventListener('touchend', handleTouchEnd);
  };
}, [handleMouseUp, handleTouchEnd, pollId, voteAuthenticationMethod]);

  
  
  
  
  

const classifyColor = (hue) => {
  if (labels.length === 3 && isLegacyPoll) {
    // Legacy poll logic remains unchanged
    if (hue >= 60 && hue <= 180) {
      return labels[0];
    } else if (hue > 300 || hue <= 60) {
      return labels[2];
    } else if (hue > 180 && hue <= 300) {
      return labels[1];
    } else {
      return 'Mixed Feelings';
    }
  } else {
    // Adjusted non-legacy poll logic
    const numOpinions = labels.length;
    const segmentSize = 360 / numOpinions;
    const initialOffset = -segmentSize / 2;

    // Use the getRotationOffset function for proper rotation
    const rotationOffset = getRotationOffset(numOpinions);

    // Adjust the hue to account for rotation and offset
    const adjustedHue = (hue + rotationOffset - initialOffset + 360) % 360;
    const optionIndex = Math.floor(adjustedHue / segmentSize) % numOpinions;

    return labels[optionIndex];
  }
};






const calculateColorPercentages = (votesArray) => {
  if (!Array.isArray(votesArray)) {
    return [];
  }

  const colorCounts = votesArray.reduce((acc, vote) => {
    const color = hsvaToHslaString({ h: vote.choice, s: vote.saturation, v: 100, a: 1 });
    const classification = classifyColor(vote.choice);
    const opinion = mapColorToOpinion({ h: vote.choice, s: vote.saturation, v: 100, a: 1 });

    if (!acc[classification]) {
      acc[classification] = [];
    }

    acc[classification].push({
      color,
      confidence: vote.confidence,
      timestamp: vote.createdAt,
      opinion,
      hue: vote.choice,
      saturation: vote.saturation,
      classification, // Include classification
    });
    return acc;
  }, {});

  const totalVotes = votesArray.length;
  const sortedColors = labels
    .map(classification => {
      if (!colorCounts[classification]) return [];
      return colorCounts[classification]
        .sort((a, b) => a.confidence - b.confidence)
        .map(entry => ({
          ...entry,
          percentage: (1 / totalVotes) * 100,
        }));
    })
    .flat();

  return sortedColors;
};


  
  

  const colorPercentages = calculateColorPercentages(votes);

  const counts = {};
labels.forEach((label) => {
  counts[label] = votes.filter(vote => classifyColor(vote.choice) === label).length;
});


  // const calculateConfidencePercentages = (votesArray, classification, color) => {
  //   const filteredVotes = votesArray.filter(vote => classifyColor(vote.choice) === classification);
  
  //   const confidenceCounts = filteredVotes.reduce((acc, vote) => {
  //     if (!acc[vote.confidence]) {
  //       acc[vote.confidence] = 0;
  //     }
  //     acc[vote.confidence]++;
  //     return acc;
  //   }, {});
  
  //   const totalVotes = filteredVotes.length;
  //   const confidencePercentages = [];
  
  //   // Determine which confidence labels to use for the current classification
  //   const classificationIndex = labels.indexOf(classification);
  //   const classificationConfidenceLabels = confidenceLabels[classificationIndex] || ["Very Low", "Low", "Medium", "High", "Very High"];
  
  //   for (let i = 0; i < classificationConfidenceLabels.length; i++) {
  //     const count = confidenceCounts[i] || 0;
  //     const percentage = totalVotes ? ((count / totalVotes) * 100).toFixed(1) : 0;
  //     if (percentage > 0) {
  //       const adjustedColor = color.h === 120 ? adjustGreenSaturation(i) : hsvaToHslaString({ h: color.h, s: (i + 1) * 20, v: 100, a: 1 });
  //       confidencePercentages.push({
  //         count,
  //         percentage,
  //         color: adjustedColor,
  //         label: classificationConfidenceLabels[i] // Use the new confidence labels array
  //       });
  //     }
  //   }
  
  //   return confidencePercentages;
  // };



  
const calculateOptionVotePercentages = (votes, labels) => {
  const totalVotes = votes.length;
  return labels.map((label) => {
    const optionVotes = votes.filter((vote) => classifyColor(vote.choice) === label).length;
    const percentage = totalVotes ? ((optionVotes / totalVotes) * 100).toFixed(1) : '0.0';
    return { label, percentage };
  });
};


const optionVotePercentages = calculateOptionVotePercentages(votes, labels);


// Step 1: Calculate the total vote count for each label
const calculateOptionVoteCounts = (votes, labels) => {
  return labels.map((label) => {
    const optionVotes = votes.filter((vote) => classifyColor(vote.choice) === label).length;
    return { label, count: optionVotes };
  });
};

// Step 2: Sort the labels by the total vote count in descending order
const sortedLabels = calculateOptionVoteCounts(votes, labels).sort(
  (a, b) => b.count - a.count
);


const calculateConfidencePercentages = (votesArray, classification, baseColor) => {
  const filteredVotes = votesArray.filter(
    (vote) => classifyColor(vote.choice) === classification
  );

  if (
    !confidenceLabels ||
    !confidenceLabels[labels.indexOf(classification)] ||
    confidenceLabels[labels.indexOf(classification)].length === 0
  ) {
    // No confidence levels, treat all votes as a single confidence level
    const totalVotes = filteredVotes.length;
    const percentage = totalVotes
      ? ((totalVotes / votesArray.length) * 100).toFixed(1)
      : '0.0';

    return [
      {
        count: totalVotes,
        percentage,
        color: hsvaToHslaString({ h: baseColor.h, s: 100, v: 100, a: 1 }),
        label: '', // No confidence label
      },
    ];
  }

  // Existing logic for when confidence levels are present
  const confidenceCounts = filteredVotes.reduce((acc, vote) => {
    if (!acc[vote.confidence]) {
      acc[vote.confidence] = 0;
    }
    acc[vote.confidence]++;
    return acc;
  }, {});

  const totalVotes = filteredVotes.length;
  const confidencePercentages = [];

  const classificationIndex = labels.indexOf(classification);
  const classificationConfidenceLabels =
    confidenceLabels[classificationIndex] || ['Very Low', 'Low', 'Medium', 'High', 'Very High'];
  const numConfidenceLevels = classificationConfidenceLabels.length;

  for (let i = 0; i < numConfidenceLevels; i++) {
    const count = confidenceCounts[i] || 0;
    const percentage = totalVotes ? ((count / totalVotes) * 100).toFixed(1) : '0.0';
    if (percentage > 0) {
      const adjustedColor = computeColor(baseColor.h, i, numConfidenceLevels);
      confidencePercentages.push({
        count,
        percentage,
        color: adjustedColor,
        label: classificationConfidenceLabels[i],
      });
    }
  }

  return confidencePercentages;
};

  
  
  const computeColor = (baseHue, confidenceIndex, totalConfidenceLevels) => {
  const saturation = ((confidenceIndex + 1) / totalConfidenceLevels) * 100;
  return hsvaToHslaString({ h: baseHue, s: saturation, v: 100, a: 1 });
};



  
  
  // Calculate confidence percentages for each label
  const confidencePercentagesLabel1 = calculateConfidencePercentages(
    votes,
    labels[0],
    confidenceColors[labels[0]]
  );
  const confidencePercentagesLabel2 = calculateConfidencePercentages(
    votes,
    labels[1],
    confidenceColors[labels[1]]
  );
  const confidencePercentagesLabel3 = calculateConfidencePercentages(
    votes,
    labels[2],
    confidenceColors[labels[2]]
  );
  

  
  const adjustGreenSaturation = (confidenceLevel) => {
    switch (confidenceLevel) {
      case 4:
        return 'rgb(0, 255, 0)'; // Very High
      case 3:
        return 'rgb(90, 255, 90)'; // High
      case 2:
        return 'rgb(140, 255, 140)'; // Medium
      case 1:
        return 'rgb(190, 255, 190)'; // Low
      case 0:
        return 'rgb(220, 255, 220)'; // Very Low
      default:
        return 'rgb(0, 255, 0)'; // Default green if unknown level
    }
  };
  

  
 
  const confidencePercentagesAgree = calculateConfidencePercentages(votes, labels[0], confidenceColors[labels[0]]);
  const confidencePercentagesNeutral = calculateConfidencePercentages(votes, labels[1], confidenceColors[labels[1]]);
  const confidencePercentagesDisagree = calculateConfidencePercentages(votes, labels[2], confidenceColors[labels[2]]);
  
  const handleDropdownToggle = (e) => {
    e.stopPropagation();
    setIsDropdownOpen((prev) => !prev);
  };
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);
  
  


  const navigate = useNavigate();

  const handleViewLiveStatsClick = async () => {
    const subscriptionStatus = await checkSubscriptionStatus(userId);
    if (subscriptionStatus === 'Free') {
      setShowUpgradeModal(true);
    } else {
      // Pass additional state to indicate the source page
      navigate(`/dashboard/${hash}`, { state: { from: '/profile' } });
    }
  };
  
  

  const handleUsernameClick = () => {
    if (username === creatorUsername) {
      navigate('/profile');
    } else {
      navigate(`/profile/${creatorUsername}`);
    }
  };

  // Impression tracking logic
  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          impressionTimer.current = setTimeout(() => {
            recordImpression();
          }, 2000); // 2 seconds
        } else {
          clearTimeout(impressionTimer.current);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5 // 50% of the element should be visible
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);


  const handleClickOutside = (event) => {
    if (colorWheelRef.current && !colorWheelRef.current.contains(event.target)) {
      setWheelActive(false);
      setShowSubmit(false);
      setBodyBackgroundColorWithFade('', 0.5); // Reset background color with fade
    }
  };

  const formatOpinionsCount = (count) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'; // Format as millions
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'k'; // Format as thousands
    }
    return count.toString(); // Return as-is for counts less than 1000
  };
  



  
  

  const setBodyBackgroundColorWithFade = (color, duration) => {
    document.body.style.transition = `background-color ${duration}s`;
    document.body.style.backgroundColor = color;
    
    setTimeout(() => {
      document.body.style.transition = ''; // Remove the transition after the duration to make subsequent changes immediate
    }, duration * 1000);
  };

  const setBodyBackgroundColor = (color) => {
    document.body.style.transition = ''; // Ensure no transition
    document.body.style.backgroundColor = color;
  };
  
  

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);
  

  const recordImpression = async () => {
    logger.log(`username: ${username}, creatorUsername: ${creatorUsername}`);

    if (username && username === creatorUsername) {
      logger.log(`Impression not recorded: user (${username}) is the poll creator (${creatorUsername})`);
      return;
    }

    logger.log(`Recording impression for user (${userId}) on poll (${pollId})`);

    try {
      const response = await fetch('/api/record-impression', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ pollId, userId })
      });

      if (!response.ok) {
        throw new Error('Failed to record impression');
      }

      logger.log(`Impression recorded successfully for user (${userId}) on poll (${pollId})`);
    } catch (error) {
      logger.error('Error recording impression:', error);
    }
  };

  return (

   

    
    <>

{showShareModal && (
  <PollShareModal
    pollLink={`${window.location.origin}/poll/${hash}`}
    onClose={() => setShowShareModal(false)} // Close the modal
  />
)}
      {useCaptcha && !captchaVerified && (
  <div className="captcha-overlay">
    <div className="captcha-container">
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        onChange={handleCaptchaSuccess}
        onExpired={handleCaptchaExpire}
        theme="dark"
      />
    </div>
  </div>
)}

      <ReportPollModal
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
        onSubmit={handleReportSubmit}
      />
  

      <div
        className="color-picker-container"
        style={{
          position: 'relative',
        }}
        ref={containerRef}
        onClick={pollIsInactive ? null : handleActivateWheel}
      >
        <div className="header">
          {isLoggedIn && username === creatorUsername && isProfileView ? (
            <i
              className={`fa-${showAnalytics ? 'solid' : 'regular'} fa-gear fa-2x analytics-icon`}
              onClick={handleAnalyticsClick}
            ></i>
          ) : (
            <div className="dropdown" ref={dropdownRef}>
              <i
                className="fa-solid fa-ellipsis fa-2x poll-dropdown-icon"
                onClick={handleDropdownToggle}
              ></i>
              <DropdownMenu
                isOpen={isDropdownOpen}
                onClose={() => setIsDropdownOpen(false)}
                items={[
                  { label: 'Report Poll', onClick: () => setIsReportModalOpen(true) },
                  { label: 'Share Poll', onClick: handleShareClick },
                ]}
                align="left" // Align dropdown to the left in the Poll component
              />
            </div>
          )}
          <div className={`logo-container ${logoFill ? 'filled' : ''}`}>
            <img
              src={logo || DEFAULT_LOGO_URL}
              alt="Logo"
              className="logo"
              style={{
                cursor: imageAction === 1 ? 'pointer' : 'auto',
                borderRadius: roundedCorners ? '10px' : '0px', // Add border-radius conditionally
              }}
              onClick={() => {
                if (imageAction === 1) {
                  setIsImageModalOpen(true); // Open modal if action is "Expand Image"
                } else {
                  navigate(`/poll/${hash}`); // Navigate to poll if action is "Link to Poll"
                }
              }}
            />
          </div>
  
          <i className="fa-regular fa-share-square fa-2x share-icon" onClick={handleShareClick}></i>
        </div>
  
        {showAnalytics ? (
  <div className="analytics">
    <h3 className="tools-question">{detectAndReplaceLinks(question)}</h3>
    <h3>Tools</h3>
    <div className={`poll-dropdown-wrapper ${showLiveStats ? 'active' : ''}`}>
                  <button
                    className="poll-dropdown-button"
                    onClick={() => setShowLiveStats(!showLiveStats)}
                  >
                    <span>Live Stats</span>
                    <span>{showLiveStats ? '▲' : '▼'}</span>
                  </button>
                  <div className="poll-dropdown-content">
                  <p>Watch votes arrive in real-time using the Live Dashboard</p>
                    <button onClick={handleViewLiveStatsClick} className="tools-button">
                      View Live Stats
                    </button>
                    {showUpgradeModal && <UpgradeModal onClose={() => setShowUpgradeModal(false)} />}
                  </div>
                </div>
    <div className={`poll-dropdown-wrapper ${showCreateReport ? 'active' : ''}`}>
      <button
        className="poll-dropdown-button"
        onClick={() => setShowCreateReport(!showCreateReport)}
      >
        <span>Data Report</span>
        <span>{showCreateReport ? '▲' : '▼'}</span>
      </button>
      <div className="poll-dropdown-content">
        <p>Generates a 5-page PDF report, including vote statistics, detailed summaries, and graphs displaying gender, location, and vote trends over time.</p>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
          <button
            onClick={() => window.open("/report-sample.pdf", "_blank")}
            style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}
          >
            <img src={PdfImage} alt="Download PDF" style={{ width: '128px', height: '128px', objectFit: 'contain' }} />
          </button>
          <button
            onClick={handleCreateReportClick}
            className="tools-button"
            disabled={isGeneratingReport}
          >
            {isGeneratingReport ? loadingMessage : 'Create Report'}
          </button>
        </div>
        {isGeneratingReport && (
          <div>
          <div className="loading-bar-container">
            <div className="loading-bar" style={{ width: `${loadingProgress}%` }}></div>
          
          </div>
          <p>   You will recieve an email when your report is ready. </p>
          </div>
        )}
        {showUpgradeModal && <UpgradeModal onClose={() => setShowUpgradeModal(false)} />}
                  </div>
                </div>
                <div className={`poll-dropdown-wrapper ${showDownloadCSV ? 'active' : ''}`}>
                  <button
                    className="poll-dropdown-button"
                    onClick={() => setShowDownloadCSV(!showDownloadCSV)}
                  >
                    <span>Download CSV</span>
                    <span>{showDownloadCSV ? '▲' : '▼'}</span>
                  </button>
                  <div className="poll-dropdown-content">
                  <p>Downloads a CSV file that includes all the vote data from this poll. Includes Vote Choice, Vote Hue, Vote Saturation, Vote Confidence, Gender, Date, Time, and Country.</p>
                    <button onClick={handleDownloadCSVClick} className="tools-button">
                      Download CSV
                    </button>
                    {showUpgradeModal && <UpgradeModal onClose={() => setShowUpgradeModal(false)} />}
                  </div>
                </div>
                <div className={`poll-dropdown-wrapper ${showQRCode ? 'active' : ''}`}>
                  <button
                    className="poll-dropdown-button"
                    onClick={() => setShowQRCode(!showQRCode)}
                  >
                    <span>QR Code</span>
                    <span>{showQRCode ? '▲' : '▼'}</span>
                  </button>
                  <div className="poll-dropdown-content">
                    <QRCodeGenerator pollId={pollId} hash={hash} />
                  </div>
                </div>
                <div className={`poll-dropdown-wrapper ${showEmbedCode ? 'active' : ''}`}>
                  <button
                    className="poll-dropdown-button"
                    onClick={() => setShowEmbedCode(!showEmbedCode)}
                  >
                    <span>Embed Code</span>
                    <span>{showEmbedCode ? '▲' : '▼'}</span>
                  </button>
                  <div className="poll-dropdown-content">
                  <p>Use this code to embed this poll directly onto your website. </p>
                    <EmbedCodeGenerator pollId={pollId} hash={hash} />
                  </div>
                </div>

                <div className={`poll-dropdown-wrapper ${showEmbedWidget ? 'active' : ''}`}>
  <button
    className="poll-dropdown-button"
    onClick={() => setShowEmbedWidget(!showEmbedWidget)}
  >
    <span>Website Widget</span>
    <span>{showEmbedWidget ? '▲' : '▼'}</span>
  </button>
  <div className="poll-dropdown-content">
  <p>Use this code to embed a light-weight widget on your website to display this poll as a pop up.</p>
    <EmbedWidgetGenerator pollId={pollId} hash={hash} />
  </div>
</div>

                
                <div className={`poll-dropdown-wrapper ${showSettings ? 'active' : ''}`}>
                  <button
                    className="poll-dropdown-button"
                    onClick={() => setShowSettings(!showSettings)}
                  >
                    <span>Settings</span>
                    <span>{showSettings ? '▲' : '▼'}</span>
                  </button>
                  <div className="poll-dropdown-content">
                    {/* Voting Rules */}
                    <div className="poll-auth-method">
                      <label>
                        Voting Rules
                        <select
                          value={settingsVoteAuthenticationMethod}
                          onChange={(e) => {
                            setSettingsVoteAuthenticationMethod(parseInt(e.target.value));
                            setShowSaveButton(true); // Show save button when value changes
                          }}
                          className="auth-method-select"
                        >
                          <option value={1}>One vote per person</option>
                          <option value={0}>Allow multiple votes per person</option>
                        </select>
                      </label>
                    </div>
  
                    <div className="poll-visibility">
                      <label>
                        Visibility
                        <select
                          value={settingsVisibility}
                          onChange={(e) => {
                            setSettingsVisibility(e.target.value);
                            setShowSaveButton(true); // Show save button when value changes
                          }}
                          className="visibility-select"
                        >
                          <option value="Public">Public</option>
                          <option value="Private">Private</option>
                        </select>
                      </label>
                    </div>
                    {showSaveButton && (
                      <button className="save-settings-button" onClick={handleSaveSettings}>
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
          <>
            <div className="question-text">
              <h3>{detectAndReplaceLinks(question)}</h3>
            </div>
            <div style={{ minHeight: '35px' }}> {/* Adjust the minHeight as needed */}
            <div className="opinion-text">
            <h2
  ref={ref}
  style={{
    fontSize: `${fontSize}px`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'center',
  }}
>
  {opinion.baseOpinion === "What's your Opinion?" ? (
    opinion.baseOpinion
  ) : (
    <>
      <span>{opinion.baseOpinion}</span> {/* Main opinion */}
      <br />
      <span style={{ fontSize: `${fontSize * 0.8}px`, fontWeight: 'normal' }}>
        {opinion.strength} {/* Confidence level */}
      </span>
    </>
  )}
</h2>
</div>
</div>
<div className="color-wheel-wrapper" ref={colorWheelRef} style={{ position: 'relative' }}>
  {isAdvertising && showAdvertisements ? (
    <div
      className={`advertisements-wrapper ${showAdvertisements ? 'fade-in' : ''}`}
      style={{
        opacity: showAdvertisements ? 1 : 0,
        transform: showAdvertisements ? 'translateY(0)' : 'translateY(20px)',
        transition: 'opacity 0.5s ease, transform 0.5s ease',
      }}
    >
      <Advertisements
  pollId={pollId}
  userId={creatorId}
  pollHash={hash}
  opinionText={`${opinion.baseOpinion} ${opinion.strength}`}
  question={question}
  category={category}
  city={city}
/>

    </div>
  ) : pollIsInactive ? (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: '#5d5d5d',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        color: 'white',
        fontFamily: 'Poppins',
        textAlign: 'center',
        flexDirection: 'column',
        border: isPollNotStarted ? '4px solid' : 'none',
      }}
      className={isPollNotStarted ? 'animated-border' : ''}
    >
      {isPollEnded ? (
        <>
          <div className="countdown-text">Voting ended</div>
          <div className="end-date-text">{new Date(endDate).toLocaleString()}</div>
        </>
      ) : (
        <>
          <div className="countdown-text">Voting starts in</div>
          <div className="countdown-timer">{remainingTime}</div>
          <div className="start-date-text">{new Date(startDate).toLocaleString()}</div>
        </>
      )}
      {isConnectedPoll && (
        <button 
          onClick={onVoteSubmit} 
          className="inactive-skip-button"
        >
          Skip
        </button>
      )}
    </div>
  ) : (
    <>
      <Wheel
        color={hsva}
        onChange={handleChange}
        width={size}
        height={size}
        style={{
          opacity: wheelActive ? 1 : alreadyVoted && voteAuthenticationMethod === 1 ? 0.2 : 0.5,
          pointerEvents: wheelActive && !isSubmitting && !showRedirectButton ? 'auto' : 'none', // Disable the wheel if submitting or if the redirect button is visible
        }}
      />
      {alreadyVoted && voteAuthenticationMethod === 1 && !showRedirectButton && (
        <div
          className={`fade-in ${alreadyVoted ? 'show' : ''}`}
          style={{
            position: 'absolute',
            top: isConnectedPoll ? '50%' : '50%', 
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '20px',
            fontWeight: '400',
            color: 'white',
            fontFamily: 'Poppins',
          }}
        >
          Vote Submitted!
        </div>
)}
{alreadyVoted && voteAuthenticationMethod === 0 && !showRedirectButton && (
  <div
    className={`fade-in ${alreadyVoted ? 'show' : ''}`}
    style={{
      position: 'absolute',
      top: isConnectedPoll ? '50%' : '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '20px',
      fontWeight: '400',
      color: 'white',
      fontFamily: 'Poppins',
    }}
  >
    Vote Submitted!
  </div>
)}


{alreadyVoted && !showRedirectButton && isConnectedPoll && voteAuthenticationMethod === 1 && (
  <div
    style={{
      position: 'absolute',
      top: '60%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '16px',
      fontWeight: '400',
      color: 'white',
      fontFamily: 'Poppins',
      textAlign: 'center',
    }}
  >
    <button 
      onClick={onVoteSubmit} 
      className="skip-button"
    >
      Skip
    </button>
  </div>
)}


                  {showSubmit && wheelActive && !animationVisible && !showRedirectButton && (
                    <SubmitButton onClick={handleSubmit} />
                  )}
                  {animationVisible && (
                      <Player
                        autoplay
                        loop={false}
                        src={animationData}
                        style={{
                          height: '150px',
                          width: '150px',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                        onEvent={(event) => {
                          if (event === 'complete') {
                            setAnimationVisible(false);
                            if (redirectLink) {
                              setShowSubmit(false);
                              setShowRedirectButton(true);
                            } else {
                              setShowSubmit(true);
                            }
                          }
                        }}
                      />
                    )}

                    {showInitialAnimation && (
                     <Lottie
                     animationData={clickAnimationData}
                     loop={true}
                     autoplay={true}
                     style={{
                       height: '150px',
                       width: '150px',
                       position: 'absolute',
                       top: '50%',
                       left: '50%',
                       transform: 'translate(-50%, -50%)',
                       pointerEvents: 'none',
                       zIndex: 1000,
                     }}
                   />
                    )}

                  {showRedirectButton && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      {logger.log('Rendering RedirectButton with:', redirectLink, redirectMessage)}
                      <RedirectButton
                        redirectLink={redirectLink}
                        redirectMessage={redirectMessage}
                        onRedirect={handleRedirect}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="total-votes">
  <span>{formatOpinionsCount(optimisticTotalVotes)} {totalVotes === 1 ? 'Opinion' : 'Opinions'}</span>
</div>

<ColorBar
  colorPercentages={colorPercentages}
  counts={counts}
  labels={labels}
  compressionFactor={compressionFactor}
/>
            <div className="dropdown-toggle-wrapper">
            <div className="dropdown-toggle" onClick={(e) => { e.stopPropagation(); setShowDropdown(!showDropdown); }}>
              {showDropdown ? '▲ Hide Stats' : '▼ Show Stats'}
            </div>
            </div>
            
            {showDropdown && (
              <>
                <PollAreaChart key={votes.length} votes={votes} labels={labels} />
                <div className="confidence-bars">
  {sortedLabels.map(({ label }, index) => {
    const confidencePercentages = calculateConfidencePercentages(
      votes,
      label,
      confidenceColors[label]
    );

    const totalConfidenceVotes = confidencePercentages.reduce(
      (sum, percentage) => sum + parseFloat(percentage.percentage || 0),
      0
    );

    if (totalConfidenceVotes > 0) {
      // Render only if there are votes
      const optionVotePercentage = optionVotePercentages.find(
        (option) => option.label === label
      )?.percentage;

      const labelIndex = labels.indexOf(label);
      const confidenceLabelsForOption = confidenceLabels[labelIndex];

      return (
        <React.Fragment key={index}>
          <ConfidenceBar
            label={label}
            confidencePercentages={confidencePercentages}
            optionPercentage={optionVotePercentage}
            className="confidence-bar"
            compressionFactor={compressionFactor}
            confidenceLabels={confidenceLabelsForOption}
          />
        </React.Fragment>
      );
    }

    return null; // Skip rendering for labels with no votes
  })}
</div>
              </>
            )}
            <div className="poll-creator creator-username">
              {isTemporary ? (  // Check if the poll is temporary using the prop
                <span style={{ textDecoration: 'none', cursor: 'default', color: 'grey' }}>
                  Poll not Claimed
                </span>
              ) : (
<span className="creator-link">@{creatorUsername}</span>
              )}
            </div>
            
            {isTemporary && isPollInTemporaryCreations && (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <button
                  style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    backgroundColor: '#00d131',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={handleClaimPoll}
                >
                  Claim Your Poll!
                </button>
              </div>
            )}
            
            
            {isLoggedIn && username === creatorUsername && isProfileView && (
              <div className="delete-poll">
                <button onClick={handleDeleteClick}>Delete Poll</button>
              </div>
            )}
            {showConfirmDelete && (
              <div className="confirm-delete">
                <p>Are you sure you want to delete the poll:<br />"{question}" ?</p>
                <button onClick={handleConfirmDelete}>Yes</button>
                <button onClick={() => setShowConfirmDelete(false)}>No</button>
              </div>
            )}
            {showFinalConfirmDelete && (
              <div className="confirm-delete">
                <p>This action is not undoable, confirm your intent to delete this poll.</p>
                <button onClick={handleFinalConfirmDelete}>Confirm Delete</button>
                <button onClick={() => setShowFinalConfirmDelete(false)}>Cancel</button>
              </div>
            )}
          </>
        )}
        <ImageModal
          isOpen={isImageModalOpen}
          onClose={() => setIsImageModalOpen(false)}
          imageUrl={logo || DEFAULT_LOGO_URL}
        />
      </div>


    </>



  );
  
  
  
  
      
};

export default Poll;