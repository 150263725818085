import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import logger from './logger';


const QRCodeGenerator = ({ pollId, hash }) => {
  const downloadQRCode = () => {
    const canvas = document.getElementById(`qr-code-canvas-${pollId}`);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${hash}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div style={{ 
      display: 'inline-grid'
    }}>
      <QRCodeCanvas
        id={`qr-code-canvas-${pollId}`}
        value={`${window.location.origin}/poll/${hash}`}
        size={512} // Adjust size for better visibility
        bgColor={"#ffffff"}
        fgColor={"#202021"}
        level={"L"}
        includeMargin={false} // Disable internal margin for QR code
        imageSettings={{
          src: require("./qr-icon.png"), // Use require to ensure correct path resolution
          x: undefined,
          y: undefined,
          height: 100, // Adjust image height
          width: 100, // Adjust image width
          excavate: true,
        }}
        style={{
          height: '200px',
          width: '200px',
          marginTop: '15px',
          borderRadius: '15px',
          padding: '15px',
          boxSizing: 'content-box',
          background: 'white',
          marginBottom: '10px'
        }}
      />
      <button onClick={downloadQRCode} className="tools-button">Download QR-Code</button>
    </div>
  );
};

export default QRCodeGenerator;