import React, { useState, useEffect, useRef } from 'react';
import './PollSuccessModal.css';
import logger from './logger';

const PollSuccessModal = ({ pollLink, onClose, isTemporary }) => {
  const [isCopied, setIsCopied] = useState(false);
  const modalRef = useRef(null);

  const handleCopy = () => {
    navigator.clipboard.writeText(pollLink)
      .then(() => setIsCopied(true))
      .catch((error) => logger.error('Failed to copy link:', error));
  };

  const handleShare = async () => {
    try {
      await navigator.share({
        title: 'Poll',
        text: "What's your opinion?",
        url: pollLink,
      });
    } catch (error) {
      logger.error('Error sharing poll link:', error);
      alert('Failed to share poll link');
    }
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="poll-success-modal-overlay">
      <div className="poll-success-modal-content" ref={modalRef}>
        <h2 className="poll-success-modal-title">Poll Created!</h2>
        <div className="poll-success-animation-container">
          <lottie-player
            src="https://lottie.host/965a337d-ab62-4cc6-9548-1d93ea5871d0/balI2CXoSd.json"
            background="transparent"
            speed="1"
            style={{ width: '400px', height: '400px' }}
            autoplay
          />
        </div>
        {/* Conditionally render the warning if the poll is temporary */}
        {isTemporary && (
          <div className="temporary-poll-warning">
            This Poll is live for only 48 hours. Claim it to keep it forever!
          </div>
        )}
        <div className="poll-success-link-container">
          <input type="text" value={pollLink} readOnly className="poll-success-link-input" />
          <button
            onClick={handleCopy}
            className={`poll-success-copy-button ${isCopied ? 'copied' : ''}`}
          >
            <i className={isCopied ? 'fa-regular fa-check' : 'fa-regular fa-copy'}></i>
          </button>
        </div>
        <div className="poll-success-modal-buttons">
          <button onClick={onClose} className="poll-success-close-button">Close</button>
          <button onClick={handleShare} className="poll-success-close-button">More Share Options</button>
        </div>
      </div>
    </div>
  );
};

export default PollSuccessModal;
