import React, { useEffect, useRef, useState } from 'react';
import './PollSuccessModal.css';
import logger from './logger';

const PollShareModal = ({ pollLink, onClose }) => {
  const [isCopied, setIsCopied] = useState(false); // State to track if the link is copied
  const modalRef = useRef(null); // Create a reference for the modal content

  const handleCopy = () => {
    navigator.clipboard.writeText(pollLink)
      .then(() => setIsCopied(true)) // Set the copied state to true on success
      .catch((error) => logger.error('Failed to copy link:', error)); // Log any errors
  };

  const handleShare = async () => {
    try {
      await navigator.share({
        title: 'Poll',
        text: "What's your opinion?",
        url: pollLink,
      });
    } catch (error) {
      logger.error('Error sharing poll link:', error);
      alert('Failed to share poll link');
    }
    onClose(); // Close the modal after sharing
  };

  // Close the modal if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); // Close the modal
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside); // Handle touch devices

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="poll-success-modal-overlay">
      <div className="poll-success-modal-content" ref={modalRef}>
        <h2 className="poll-success-modal-title">Share Poll</h2>
        <div className="poll-success-link-container">
          <input type="text" value={pollLink} readOnly className="poll-success-link-input" />
          <button
            onClick={handleCopy}
            className={`poll-success-copy-button ${isCopied ? 'copied' : ''}`} // Dynamic class based on state
          >
            <i className={isCopied ? 'fa-regular fa-check' : 'fa-regular fa-copy'}></i> {/* Change icon */}
          </button>
        </div>
        <div className="poll-success-modal-buttons">
          <button onClick={onClose} className="poll-success-close-button">Close</button>
          <button onClick={handleShare} className="poll-success-close-button">More Share Options</button>
        </div>
      </div>
    </div>
  );
};

export default PollShareModal;
