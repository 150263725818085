import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Poll from './Poll';
import logger from './logger';


const EmbedPoll = ({ isLoggedIn, userId, username }) => {
  const { hash } = useParams();
  const [poll, setPoll] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    logger.log('Fetching poll with hash:', hash);
    fetch(`/polls/${hash}`)
      .then(response => {
        logger.log('Response:', response);
        return response.json();
      })
      .then(data => {
        logger.log('Poll data:', data);
        setPoll(data.poll);
        setLoading(false);
      })
      .catch(error => {
        logger.error('Error fetching poll:', error);
        setLoading(false);
      });

    document.body.style.backgroundColor = 'transparent';

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [hash]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!poll) {
    return <div>Poll not found</div>;
  }

  const wrapperStyle = {
    width: window.innerWidth <= 768 ? '95%' : '450px', // 95% for mobile, 450px for desktop
    margin: '0 auto',
    overflow: 'hidden',
    display: 'grid',
    marginTop: window.innerWidth <= 768 ? '0' : '50%', // 50% top margin for desktop, 0 for mobile
  };
  
  const poweredByWrapperStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  };

  const poweredByStyle = {
    height: '30px', // Set the width of the powered by image
    display: 'block',
    marginTop: '10px'
  };

  return (
    <div className="EmbedPoll-wrapper" style={wrapperStyle}>
      <Poll
        pollId={poll._id}
        question={poll.question}
        labels={poll.labels}
        logo={poll.logo || "/logo-poll.png"} // Use the logo from the poll object or the default if not available
        logoFill={poll.logoFill}
        votes={poll.votes}
        hash={hash}
        creatorUsername={poll.creatorUsername}
        isLoggedIn={isLoggedIn}
        userId={userId}
        username={username}
        voteAuthenticationMethod={poll.voteAuthenticationMethod}
        confidenceLabels={poll.confidenceLabels}
        imageAction={poll.imageAction}
        roundedCorners={poll.roundedCorners}
        startDate={poll.startDate}
        endDate={poll.endDate}
        scheduledPoll={poll.scheduledPoll}
        totalVotes={poll.totalVotes}
      />
      <div style={poweredByWrapperStyle}>
        <img 
          src="https://opinari-images.nyc3.cdn.digitaloceanspaces.com/static/poweredby.png" 
          alt="Powered by Opinari" 
          style={poweredByStyle}
        />
      </div>
    </div>
  );
};

export default EmbedPoll;
