import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import './LiveDashboard.css';
import ColorBar from './ColorBar';
import ConfidenceBar from './ConfidenceBar';
import { hsvaToHslaString } from '@uiw/color-convert';
import { Chart } from 'react-google-charts';
import PollAreaChart from './PollAreaChart';
import QRCodeGeneratorDashboard from './QRCodeGeneratorDashboard';
import DashboardListItem from './DashboardListItem';
import logger from './logger';
import UpgradeModal from './UpgradeModal';
import Placeholder from '../assets/live-dashboard.png';
import HomePagePoll from './HomePagePoll';


const LiveDashboard = ({ userId, username, userEmail, isLoggedIn }) => {
  const { hash } = useParams();
  const navigate = useNavigate();
  const [polls, setPolls] = useState([]);
  const [selectedPoll, setSelectedPoll] = useState(null);
  const [liveVotes, setLiveVotes] = useState([]);
  const [colorPercentages, setColorPercentages] = useState([]);
  const [counts, setCounts] = useState({});
  const [labels, setLabels] = useState([]);
  const [confidenceLabels, setConfidenceLabels] = useState([]);
  const [geoChartData, setGeoChartData] = useState([['Country', 'Votes']]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const location = useLocation();
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Aggregating data...');
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const [compressionFactor, setCompressionFactor] = useState(1);

  // Helper Functions

  const checkSubscriptionStatus = async (userId) => {
    try {
      const response = await fetch(`/auth/subscription-status/${userId}`);
      if (!response.ok) throw new Error('Failed to fetch subscription status');
      const data = await response.json();
      return data.subscriptionStatus;
    } catch (error) {
      logger.error('Error fetching subscription status:', error);
      return null;
    }
  };

  const getRotationOffset = (numOptions) => {
    switch (numOptions) {
      case 2:
        return 180;
      case 3:
        return 208;
      case 4:
        return 222;
      case 5:
        return 210;
      case 6:
        return 565;
      case 7:
        return 540;
      case 8:
        return 550;
      case 9:
        return 550;
      case 10:
        return 555;
      case 11:
        return 555;
      case 12:
        return 555;
      default:
        return 0;
    }
  };

  const classifyColor = (hue, labels) => {
    const numOpinions = labels.length;
    if (numOpinions === 0) return 'No Opinion';

    const segmentSize = 360 / numOpinions;
    const initialOffset = -segmentSize / 2;
    const rotationOffset = getRotationOffset(numOpinions);
    const adjustedHue = (hue + rotationOffset - initialOffset + 360) % 360;
    const optionIndex = Math.floor(adjustedHue / segmentSize) % numOpinions;

    return labels[optionIndex];
  };

  const computeColor = (baseHue, confidenceIndex, totalConfidenceLevels) => {
    const saturation = ((confidenceIndex + 1) / totalConfidenceLevels) * 100;
    return hsvaToHslaString({ h: baseHue, s: saturation, v: 100, a: 1 });
  };

  const generateConfidenceColors = () => {
    const numOpinions = labels.length;
    const colors = {};

    if (numOpinions <= 3) {
      // Old logic for 2 or 3 options
      const segmentSize = 360 / numOpinions;
      const initialOffset = 120;

      labels.forEach((label, index) => {
        const baseHue = (index * segmentSize + initialOffset) % 360;
        colors[label] = { h: baseHue, s: 100, v: 100, a: 1 };
      });
    } else {
      // New logic for more than 3 options
      const segmentSize = 360 / numOpinions;
      const initialOffset = -segmentSize / 2;
      const rotationOffset = getRotationOffset(numOpinions);

      labels.forEach((label, index) => {
        // Center of each segment
        const adjustedHue = (index * segmentSize + segmentSize / 2 + 360) % 360;
        // Reverse calculation to get base hue
        const baseHue = (adjustedHue - rotationOffset + initialOffset + 360) % 360;
        colors[label] = { h: baseHue, s: 100, v: 100, a: 1 };
      });
    }

    return colors;
  };

  const classifyColorVote = (vote) => {
    return classifyColor(vote.choice, labels);
  };

  const getVotesByCountry = (votes) => {
    const countryCounts = {};
    for (const vote of votes) {
      const countryCode = vote.country;
      if (countryCode) {
        countryCounts[countryCode] = (countryCounts[countryCode] || 0) + 1;
      }
    }
    return Object.entries(countryCounts).map(([country, count]) => [country, count]);
  };

  // Fetch Polls on Mount
  useEffect(() => {
    fetch(`/polls?creator=${userId}&limit=0&includePrivate=true`)
      .then((response) => response.json())
      .then((data) => {
        setPolls(data.polls || []);
        setLoading(false);

        if (hash) {
          const poll = data.polls?.find((poll) => poll.hash === hash);
          if (poll) {
            setSelectedPoll(poll._id);
          } else {
            logger.error('Poll not found');
          }
        }
      })
      .catch((error) => {
        logger.error('Error fetching user polls:', error);
        setLoading(false);
        setNoData(true);
      });
  }, [userId, hash]);

  // Fetch Live Votes when a Poll is Selected
  useEffect(() => {
    if (selectedPoll === null) {
      // Fetch the list of polls to show when no poll is selected
      fetch(`/polls?creator=${userId}&limit=0&includePrivate=true`)
        .then(response => response.json())
        .then(data => {
          setPolls(data.polls || []);
          setLoading(false);
        })
        .catch(error => {
          logger.error('Error fetching user polls:', error);
          setLoading(false);
          setNoData(true);
        });
    } else {
      // Fetch the details for the selected poll
      const fetchLiveVotes = async () => {
        try {
          const response = await fetch(`/api/polls/${selectedPoll}/details`);
          if (response.ok) {
            const data = await response.json();
            setLiveVotes(data.votes || []);
            setLabels(data.poll.labels || []);
            setConfidenceLabels(data.poll.confidenceLabels || []);
            setColorPercentages(calculateColorPercentages(data.votes || [], data.poll.labels || []));
            setCounts(calculateCounts(data.votes || [], data.poll.labels || []));
            setGeoChartData([['Country', 'Votes'], ...getVotesByCountry(data.votes)]);
            setCompressionFactor(data.compressionFactor || 1); // Set compressionFactor if available
          } else {
            logger.error('Error fetching live votes:', response.statusText);
          }
        } catch (error) {
          logger.error('Error fetching live votes:', error);
        }
      };
      fetchLiveVotes();
    }
  }, [userId, selectedPoll]);

  // WebSocket Connection for Live Updates
  useEffect(() => {
    let ws;
    let reconnectAttempts = 0;
    let isComponentMounted = true;

    const connectWebSocket = () => {
      const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
      ws = new WebSocket(`${protocol}://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`);

      ws.onopen = () => {
        logger.log('Connected to WebSocket');
        reconnectAttempts = 0;
        if (selectedPoll) {
          ws.send(JSON.stringify({ type: 'subscribe', pollId: selectedPoll }));
        }
      };

      ws.onmessage = (event) => {
        if (!isComponentMounted) return;

        const message = JSON.parse(event.data);
        logger.log('WebSocket message received:', message);

        if (message.type === 'voteUpdate' && message.pollId === selectedPoll) {
          logger.log('Vote count update received:', message.voteCount);
          // Handle vote count updates if necessary
        } else if (message.type === 'vote' && message.pollId === selectedPoll) {
          const newVote = message.vote;
          if (newVote && newVote.choice !== undefined && newVote.saturation !== undefined) {
            setLiveVotes((prevVotes) => {
              const updatedVotes = [...prevVotes, newVote];
              logger.log('Updated live votes:', updatedVotes); // Log for debugging
              setColorPercentages(calculateColorPercentages(updatedVotes, labels));
              setCounts(calculateCounts(updatedVotes, labels));
              setGeoChartData([['Country', 'Votes'], ...getVotesByCountry(updatedVotes)]);
              return updatedVotes;
            });
          } else {
            logger.error('Invalid vote data received from WebSocket:', newVote);
          }
        }
      };

      ws.onclose = () => {
        logger.log('Disconnected from WebSocket.');
        if (isComponentMounted) {
          const timeout = Math.min(1000 * Math.pow(2, reconnectAttempts), 30000); // Cap at 30 seconds
          logger.log(`Reconnecting to WebSocket in ${timeout / 1000} seconds...`);
          setTimeout(connectWebSocket, timeout);
          reconnectAttempts++;
        }
      };

      ws.onerror = (error) => {
        logger.error('WebSocket error:', error);
        ws.close();
      };
    };

    connectWebSocket();

    return () => {
      isComponentMounted = false;
      if (ws) {
        ws.close();
      }
    };
  }, [selectedPoll, labels]);

  // Handle Poll Selection
  const handlePollClick = (poll) => {
    setSelectedPoll(poll._id);
    navigate(`/dashboard/${poll.hash}`);
  };

  // Handle Back Navigation
  const handleBackClick = () => {
    // Default back URL
    let backURL = '/dashboard';  // Assume this is the default for the list of polls

    // Check if there's a navigation state and adapt accordingly
    if (location.state?.from === '/profile') {
      backURL = '/profile';
    } else {
      // Reset the selectedPoll to ensure the list is shown
      setSelectedPoll(null);
    }

    navigate(backURL, { replace: true });
  };

  // Calculate Color Percentages
  const calculateColorPercentages = (votesArray, labels) => {
    if (!votesArray || !labels) return [];
    const totalVotes = votesArray.length;
    if (totalVotes === 0) return [];

    const colorCounts = votesArray.reduce((acc, vote) => {
      if (!vote.choice || !vote.saturation) {
        logger.error('Invalid vote data:', vote);
        return acc;
      }

      const classification = classifyColor(vote.choice, labels);
      const color = hsvaToHslaString({ h: vote.choice, s: vote.saturation, v: 100, a: 1 });

      if (!acc[classification]) {
        acc[classification] = [];
      }

      acc[classification].push({
        color,
        confidence: vote.confidence,
        timestamp: vote.createdAt,
        opinion: vote.opinion,
        hue: vote.choice,
        saturation: vote.saturation,
        classification,
      });
      return acc;
    }, {});

    const sortedColors = labels
      .map(classification => {
        if (!colorCounts[classification]) return [];
        return colorCounts[classification]
          .sort((a, b) => a.confidence - b.confidence)
          .map(entry => ({
            ...entry,
            percentage: (1 / totalVotes) * 100,
          }));
      })
      .flat();

    return sortedColors;
  };

  // Calculate Vote Counts per Label
  const calculateCounts = (votes, labels) => {
    if (!votes || !labels) return {};
    return labels.reduce((acc, label) => {
      acc[label] = votes.filter((vote) => classifyColor(vote.choice, labels) === label).length;
      return acc;
    }, {});
  };

  // Calculate Confidence Percentages
  const calculateConfidencePercentages = (votesArray, classification, baseColor) => {
    const filteredVotes = votesArray.filter(
      (vote) => classifyColor(vote.choice, labels) === classification
    );

    if (
      !confidenceLabels ||
      !confidenceLabels[labels.indexOf(classification)] ||
      confidenceLabels[labels.indexOf(classification)].length === 0
    ) {
      // No confidence levels, treat all votes as a single confidence level
      const totalVotes = filteredVotes.length;
      const percentage = totalVotes
        ? ((totalVotes / votesArray.length) * 100).toFixed(1)
        : '0.0';

      return [
        {
          count: totalVotes,
          percentage,
          color: hsvaToHslaString({ h: baseColor.h, s: 100, v: 100, a: 1 }),
          label: '', // No confidence label
        },
      ];
    }

    // Existing logic for when confidence levels are present
    const confidenceCounts = filteredVotes.reduce((acc, vote) => {
      if (!acc[vote.confidence]) {
        acc[vote.confidence] = 0;
      }
      acc[vote.confidence]++;
      return acc;
    }, {});

    const totalVotes = filteredVotes.length;
    const confidencePercentages = [];

    const classificationIndex = labels.indexOf(classification);
    const classificationConfidenceLabels =
      confidenceLabels[classificationIndex] || ['Very Low', 'Low', 'Medium', 'High', 'Very High'];
    const numConfidenceLevels = classificationConfidenceLabels.length;

    for (let i = 0; i < numConfidenceLevels; i++) {
      const count = confidenceCounts[i] || 0;
      const percentage = totalVotes ? ((count / totalVotes) * 100).toFixed(1) : '0.0';
      if (percentage > 0) {
        const adjustedColor = computeColor(baseColor.h, i, numConfidenceLevels);
        confidencePercentages.push({
          count,
          percentage,
          color: adjustedColor,
          label: classificationConfidenceLabels[i],
        });
      }
    }

    return confidencePercentages;
  };

  // Calculate Option Vote Percentages
  const calculateOptionVotePercentages = (votes, labels) => {
    const totalVotes = votes.length;
    return labels.map((label) => {
      const optionVotes = votes.filter((vote) => classifyColor(vote.choice, labels) === label).length;
      const percentage = totalVotes ? ((optionVotes / totalVotes) * 100).toFixed(1) : '0.0';
      return { label, percentage };
    });
  };

  const optionVotePercentages = calculateOptionVotePercentages(liveVotes, labels);

  // Calculate and Sort Vote Counts for Labels
  const calculateOptionVoteCounts = (votes, labels) => {
    return labels.map((label) => {
      const optionVotes = votes.filter((vote) => classifyColor(vote.choice, labels) === label).length;
      return { label, count: optionVotes };
    });
  };

  const sortedLabels = calculateOptionVoteCounts(liveVotes, labels).sort(
    (a, b) => b.count - a.count
  );

  // Generate Confidence Colors Based on Labels
  const confidenceColors = generateConfidenceColors();

  // Handle Report Generation
  const handleCreateReportClick = async () => {
    // Check subscription status at the very beginning
    const subscriptionStatus = await checkSubscriptionStatus(userId); // Pass userId to the function

    if (!['Pro', 'Elite', 'Unlimited'].includes(subscriptionStatus)) {
      setShowUpgradeModal(true);
      return;
    }

    setIsGeneratingReport(true);
    setLoadingProgress(0);
    setLoadingMessage('Aggregating data...');

    // Define the steps for the progress bar and messages
    const loadingSteps = [
      { progress: 25, duration: 13000, message: 'Aggregating data...' },
      { progress: 50, duration: 28000, message: 'Generating AI Insights...' },
      { progress: 90, duration: 22000, message: 'Rendering Charts...' },
    ];

    // Function to animate progress bar
    const animateProgressBar = async (steps, index = 0) => {
      if (index >= steps.length) {
        setLoadingMessage('Finishing up...');
        return;
      }

      const { progress, duration, message } = steps[index];
      setLoadingMessage(message);

      // Animate the progress
      const startProgress = loadingProgress;
      const increment = (progress - startProgress) / (duration / 100); // Increment per 100ms

      const interval = setInterval(() => {
        setLoadingProgress((prev) => {
          const newProgress = prev + increment;
          if (newProgress >= progress) {
            clearInterval(interval);
            setLoadingProgress(progress);
            setTimeout(() => animateProgressBar(steps, index + 1), 1000); // Pause for 1 second before next step
            return progress;
          }
          return newProgress;
        });
      }, 100);
    };

    // Start animating the progress bar
    await animateProgressBar(loadingSteps);

    // Prepare data for the report
    const totalVotes = liveVotes.length;
    const colorPercentages = calculateColorPercentages(liveVotes, labels);
    const confidencePercentages = labels.map(label => ({
      label,
      percentages: calculateConfidencePercentages(liveVotes, label, confidenceColors[label])
    }));

    const data = {
      pollId: selectedPoll,
      question: polls.find(poll => poll._id === selectedPoll)?.question || '',
      date: new Date().toISOString().slice(0, 10),
      username: username,
      userEmail: userEmail,  // Include the user's email here
      choices: labels.map(label => ({
        name: label,
        votes: liveVotes.filter(vote => classifyColor(vote.choice, labels) === label).length,
        percentage: totalVotes ? ((liveVotes.filter(vote => classifyColor(vote.choice, labels) === label).length / totalVotes) * 100).toFixed(2) : "0.00"
      })),
      colorPercentages: colorPercentages,
      counts: labels.reduce((acc, label) => {
        acc[label] = liveVotes.filter(vote => classifyColor(vote.choice, labels) === label).length;
        return acc;
      }, {}),
      labels: labels,
      confidencePercentages: confidencePercentages,
      votes: liveVotes, // Ensure votes are included here
      confidenceLabels: confidenceLabels // Add confidenceLabels here
    };

    // Log the data before sending the request
    logger.log('Data sent to PDF generation server:', data);

    try {
      const response = await fetch(`${process.env.REACT_APP_PDF_GENERATION_URL}/generate-pdf`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });
      if (!response.ok) throw new Error('Failed to generate report');
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'Poll_Report.pdf';
      link.style.display = 'none'; // Make sure the link is not visible
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      logger.error('Error generating report:', error);
    } finally {
      setLoadingProgress(100);
      setLoadingMessage('Finishing up...');
      setTimeout(() => {
        setIsGeneratingReport(false);
        setLoadingMessage('Aggregating data...');
        setLoadingProgress(0);
      }, 500);
    }
  };

  return (
    <div>
      <div className="dashboard-header">
        {selectedPoll && (
          <button className="back-button" onClick={handleBackClick}>
            ←
          </button>
        )}
      </div>
      <div className="live-dashboard">
        {loading ? (
          <div className="dashboard-list-card">
            <p>Loading...</p>
          </div>
        ) : !selectedPoll ? (
          <div className="dashboard-list-card poll-list-card">
            <h1 className="dashboard-title">Live Dashboard</h1>
            {polls.length === 0 ? (
              <>
                <p className="dashboard-subtitle">Create a Poll to view Live Stats</p>
                <Link to="/create-poll" className="profile-placeholder-add-button">+</Link>

                {/* Placeholder image */}
                <img src={Placeholder} alt="Create a poll placeholder" className="placeholder-image" />
              </>
            ) : (
              <>
                <p className="dashboard-subtitle">Select a poll to view live stats</p>
                <div className="dashboard-poll-list">
                  {polls.map((poll) => (
                    <DashboardListItem
                      key={poll._id}
                      pollId={poll._id}
                      question={poll.question}
                      labels={poll.labels}
                      logo={poll.logo}
                      logoFill={poll.logoFill}
                      hash={poll.hash}
                      isLoggedIn={!!userId}
                      userId={userId}
                      creatorId={poll.creatorId}
                      creatorUsername={poll.creatorUsername}
                      username={username}
                      isProfileView={false}
                      voteAuthenticationMethod={poll.voteAuthenticationMethod}
                      confidenceLabels={poll.confidenceLabels}
                      onPollClick={() => handlePollClick(poll)}
                      imageAction={poll.imageAction}
                      roundedCorners={poll.roundedCorners}
                      startDate={poll.startDate}
                      endDate={poll.endDate}
                      scheduledPoll={poll.scheduledPoll}
                      pollActive={poll.pollActive}
                      isLegacyPoll={poll.isLegacyPoll}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <div className="top-row-container">
              <div className="stats-cards">
                <div className="dashboard-card stats-card">
                  <div className="pulse-dot"></div>
                  <h2 className="dashboard-poll-question">
                    {polls.find(poll => poll._id === selectedPoll)?.question}
                  </h2>
                  <div className="vote-summary">
                    <h2>Total Votes: {liveVotes.length}</h2>
                    <p>
                      {labels.map(label => `${counts[label]} ${label}`).join(', ')}
                    </p>
                  </div>
                  <ColorBar
                    colorPercentages={colorPercentages}
                    counts={counts}
                    labels={labels}
                    showConfidenceLabels={true}
                    compressionFactor={compressionFactor} // Ensure compressionFactor is passed
                  />
                </div>
                <div className="dashboard-card stats-card">
                  <div className="confidence-bars">
                    {sortedLabels.map(({ label }, index) => {
                      const confidencePercentages = calculateConfidencePercentages(
                        liveVotes,
                        label,
                        confidenceColors[label]
                      );

                      const optionVotePercentage = optionVotePercentages.find(
                        (option) => option.label === label
                      )?.percentage;

                      // Get the confidence labels for the current option, default to empty array if undefined
                      const labelIndex = labels.indexOf(label);
                      const confidenceLabelsForOption = confidenceLabels[labelIndex] || [];

                      return (
                        <ConfidenceBar
                          key={index}
                          label={label}
                          confidencePercentages={confidencePercentages}
                          optionPercentage={optionVotePercentage}
                          className="confidence-bar"
                          compressionFactor={compressionFactor}
                          confidenceLabels={confidenceLabelsForOption} // Pass confidenceLabels prop, default to empty array
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="qr-card-container">
                <div className="qr-card">
                  <div className="qr-code-card">
                    <QRCodeGeneratorDashboard
                      pollId={selectedPoll}
                      hash={polls.find(poll => poll._id === selectedPoll)?.hash}
                    />
                  </div>
                </div>

                <div className="qr-card">
                  <div className="report-card">
                    <button
                      onClick={handleCreateReportClick}
                      className="dashboard-tools-button"
                      disabled={isGeneratingReport}
                    >
                      {isGeneratingReport ? loadingMessage : 'Create Data Report'}
                    </button>
                    {isGeneratingReport && (
                      <div>
                        <div className="loading-bar-container">
                          <div className="loading-bar" style={{ width: `${loadingProgress}%` }}></div>
                        </div>
                        <p>You will receive an email when your report is ready.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard-card full">
              <PollAreaChart votes={liveVotes} labels={labels} />
            </div>
            <div className="dashboard-card full">
              <Chart
                width={'100%'}
                height={'100%'}
                chartType="GeoChart"
                data={geoChartData}
                options={{
                  colorAxis: { colors: ['#daffda', '#00FF00'] },
                  backgroundColor: '#212121',
                  datalessRegionColor: '#555',
                  defaultColor: '#212121',
                  legend: 'none',
                  fill: '#212121'
                }}
              />
            </div>
          </>
        )}
      </div>

      {/* Upgrade Modal */}
      {showUpgradeModal && (
        <UpgradeModal onClose={() => setShowUpgradeModal(false)} />
      )}
    </div>
  );
};

export default LiveDashboard;
