import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MobileBg from '../assets/mobile-bg-login.jpg'; // Import mobile background
import DesktopBg from '../assets/desktop-bg-login.jpg'; // Import desktop background
import './VerifyEmail.css'; // Import a new CSS file for styling (if you don't have it already)
import logger from './logger';


const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [backgroundImage, setBackgroundImage] = useState(''); // Manage background image state

  useEffect(() => {
    // Function to determine the current background based on window width
    const updateBackgroundImage = () => {
      if (window.innerWidth < 768) {
        setBackgroundImage(MobileBg); // Mobile background
      } else {
        setBackgroundImage(DesktopBg); // Desktop background
      }
    };

    // Initial check
    updateBackgroundImage();

    // Add event listener to update background on window resize
    window.addEventListener('resize', updateBackgroundImage);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateBackgroundImage);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    logger.log('Verification token:', token);

    fetch(`/auth/verify-email?token=${token}`)
      .then((response) => {
        logger.log('Verification response status:', response.status);
        return response.json();
      })
      .then((data) => {
        logger.log('Verification response data:', data);
        if (data.error) {
          setMessage('Verification failed. Contact support@opinari.io');
        } else {
          setMessage(data.message);
          setTimeout(() => {
            navigate('/login'); // Redirect to the login page after 2 seconds
          }, 1000);
        }
      })
      .catch((error) => {
        logger.error('Error verifying email:', error);
        setMessage('Error verifying email. Contact support@opinari.io');
      });
  }, [location, navigate]);

  return (
    <div
      className="verify-email-page"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className="verify-email-content">
        <h1>Email Verification</h1>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default VerifyEmail;
