import React from 'react';
import PropTypes from 'prop-types';
import './DropdownMenu.css'; // Make sure to create this CSS file
import logger from './logger';


const DropdownMenu = ({ items, isOpen, onClose, align }) => {
  return (
    isOpen && (
      <div className={`dropdown-menu ${align}`}>
        {items.map((item, index) => (
          <div key={index} className="dropdown-item" onClick={() => { item.onClick(); onClose(); }}>
            {item.label}
          </div>
        ))}
      </div>
    )
  );
};

DropdownMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  align: PropTypes.string, // Add align prop
};

DropdownMenu.defaultProps = {
  align: 'right', // Default alignment is right
};

export default DropdownMenu;
