import React, { useState, useEffect } from 'react';
import ColorBarHomepage from './ColorBarHomepage';
import { hsvaToHslaString } from '@uiw/color-convert';
import './DashboardListItem.css';
import logger from './logger';

const DashboardListItem = ({
  pollId,
  question,
  labels,
  confidenceLabels,
  onPollClick, // Parent function for live dashboard
}) => {
  const [votes, setVotes] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080';

  useEffect(() => {
    const fetchVotes = async () => {
      try {
        const response = await fetch(`${apiUrl}/polls/${pollId}/votes`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (Array.isArray(data)) {
            setVotes(data);
          } else {
            logger.error('Fetched data is not an array:', data);
            setVotes([]);
          }
        } else {
          logger.error(`Error fetching votes for poll ${pollId}: ${response.status}`);
          setVotes([]);
        }
      } catch (error) {
        logger.error('Error fetching votes:', error);
        setVotes([]);
      }
    };

    fetchVotes();
  }, [pollId, apiUrl]);

  // Function to map HSVA to opinion based on hue and saturation
  const mapColorToOpinion = (hsva) => {
    const { h, s } = hsva;
    let baseOpinion = 'No Opinion';

    if (s === 0) return baseOpinion;

    if (h >= 60 && h <= 180) baseOpinion = labels[0];
    else if (h > 300 || h <= 60) baseOpinion = labels[2];
    else baseOpinion = labels[1];

    const strength =
      s < 20
        ? confidenceLabels[0]
        : s < 40
        ? confidenceLabels[1]
        : s < 60
        ? confidenceLabels[2]
        : s < 80
        ? confidenceLabels[3]
        : confidenceLabels[4];

    return `${strength} ${baseOpinion}`;
  };

  // Function to classify color based on hue
  const classifyColor = (hue) => {
    if (hue >= 60 && hue <= 180) return labels[0];
    else if (hue > 300 || hue <= 60) return labels[2];
    else return labels[1];
  };

  // Function to calculate color percentages similar to HomePagePoll
  const calculateColorPercentages = (votesArray) => {
    if (!Array.isArray(votesArray)) return [];

    const colorCounts = votesArray.reduce((acc, vote) => {
      const color = hsvaToHslaString({ h: vote.choice, s: vote.saturation, v: 100, a: 1 });
      const classification = classifyColor(vote.choice);
      const opinion = mapColorToOpinion({ h: vote.choice, s: vote.saturation, v: 100, a: 1 });

      if (!acc[classification]) acc[classification] = [];

      acc[classification].push({ color, confidence: vote.confidence, opinion });
      return acc;
    }, {});

    const totalVotes = votesArray.length;
    const sortedColors = [labels[2], labels[1], labels[0]]
      .map((classification) => {
        if (!colorCounts[classification]) return [];
        return colorCounts[classification].map((entry) => ({
          ...entry,
          percentage: (1 / totalVotes) * 100,
        }));
      })
      .flat();

    return sortedColors;
  };

  const colorPercentages = calculateColorPercentages(votes);

  const counts = {
    agree: votes.filter((vote) => classifyColor(vote.choice) === labels[0]).length,
    neutral: votes.filter((vote) => classifyColor(vote.choice) === labels[1]).length,
    disagree: votes.filter((vote) => classifyColor(vote.choice) === labels[2]).length,
  };

  return (
    <div
      className="dashboard-list-item__container"
      onClick={() => onPollClick(pollId)}
      style={{ cursor: 'pointer', textDecoration: 'none' }}
    >
      <div className="dashboard-list-item__question-text">
        <h3>{question.length > 30 ? `${question.substring(0, 30)}...` : question}</h3>
      </div>
      <ColorBarHomepage
        colorPercentages={colorPercentages}
        counts={counts}
        labels={labels}
      />
    </div>
  );
};

export default DashboardListItem;
