// PayoutHistoryViewer.js
import React, { useState, useEffect } from 'react';
import './CSVViewer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock } from '@fortawesome/free-solid-svg-icons';

function PayoutHistoryViewer() {
  const [payoutData, setPayoutData] = useState([
    { date: '11/15/2024', amount: 80.00, status: 'Pending' }, // Next payout
    { date: '11/01/2024', amount: 75.50, status: 'Completed' },
    { date: '10/15/2024', amount: 50.75, status: 'Completed' },
    { date: '09/30/2024', amount: 62.10, status: 'Completed' },
    { date: '09/15/2024', amount: 45.00, status: 'Completed' },
    { date: '08/31/2024', amount: 85.20, status: 'Completed' },
    { date: '08/15/2024', amount: 70.30, status: 'Completed' },
    { date: '11/01/2024', amount: 75.50, status: 'Completed' },
    { date: '10/15/2024', amount: 50.75, status: 'Completed' },
    { date: '09/30/2024', amount: 62.10, status: 'Completed' },
    { date: '09/15/2024', amount: 45.00, status: 'Completed' },
    { date: '08/31/2024', amount: 85.20, status: 'Completed' },
    { date: '08/15/2024', amount: 70.30, status: 'Completed' },
    { date: '11/01/2024', amount: 75.50, status: 'Completed' },
    { date: '10/15/2024', amount: 50.75, status: 'Completed' },
    { date: '09/30/2024', amount: 62.10, status: 'Completed' },
    { date: '09/15/2024', amount: 45.00, status: 'Completed' },
    { date: '08/31/2024', amount: 85.20, status: 'Completed' },
    { date: '08/15/2024', amount: 70.30, status: 'Completed' }
  ]);

  return (
    <div className="csv-viewer">
      <h3>Payout History</h3>
      <table className="csv-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {payoutData.length > 0 ? (
            payoutData.map((payout, index) => (
              <tr key={index}>
                <td>{payout.date}</td>
                <td>${payout.amount.toFixed(2)}</td>
                <td>
                  {payout.status === 'Completed' ? (
                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#6adb6a' }} />
                  ) : (
                    <FontAwesomeIcon icon={faClock} style={{ color: '#ffcc00' }} /> // Pending icon
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No payout data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default PayoutHistoryViewer;
