import React, { useState } from 'react';
import '../App.css';
import logger from './logger';


const InitiateResetPassword = ({ onClose }) => {
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/auth/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ emailOrUsername })
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          setMessage(data.message);
          setError('');
        } else {
          setError(data.error || 'Error sending reset email');
          setMessage('');
        }
      })
      .catch(error => {
        logger.error('Error sending reset email:', error);
        setError('Error sending reset email');
        setMessage('');
      });
  };

  return (
    <div className="Modal-overlay">
      <div className="Modal-content">
        <button className="modal-close" onClick={onClose}>×</button>
        <h2>Forgot Password</h2>
        <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <input
            type="text"
            placeholder="Email or Username"
            value={emailOrUsername}
            onChange={(e) => setEmailOrUsername(e.target.value)}
            className="Auth-input"
            required
          />
          <button type="submit" className="Auth-button">Send Reset Link</button>
        </form>
        {message && <p className="Auth-message">{message}</p>}
        {error && <p className="Auth-error">{error}</p>}
      </div>
    </div>
  );
};

export default InitiateResetPassword;
