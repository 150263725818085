import React, { useState } from 'react';
import PropTypes from 'prop-types';
import logger from './logger';


const ReportPollModal = ({ isOpen, onClose, onSubmit }) => {
  const [reportText, setReportText] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(reportText);
    setIsSubmitted(true);
  };

  return (
    isOpen && (
      <div style={styles.overlay}>
        <div style={styles.content}>
          <h2 style={styles.header}>Report Poll</h2>
          {isSubmitted ? (
            <div style={styles.thankYouMessage}>
              <p>Thank you for reporting this poll, our team will have a look and take action if the poll violates our community guidelines.</p>
              <button style={styles.closeButton} onClick={onClose}>Close</button>
            </div>
          ) : (
            <form onSubmit={handleSubmit} style={styles.form}>
              <label htmlFor="report-textarea" style={styles.label}>Please provide some details</label>
              <textarea
                id="report-textarea"
                style={styles.textarea}
                value={reportText}
                onChange={(e) => setReportText(e.target.value)}
                placeholder="Describe the issue"
                required
              />
              <div style={styles.buttons}>
                <button type="submit" style={styles.submitButton}>Submit</button>
                <button type="button" style={styles.cancelButton} onClick={onClose}>Cancel</button>
              </div>
            </form>
          )}
        </div>
      </div>
    )
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#0000007a',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    overflowY: 'auto',
  },
  content: {
    background: '#333',
    padding: '20px',
    borderRadius: '10px',
    width: '80%',
    maxWidth: '500px',
    maxHeight: '80vh',
    overflowY: 'auto',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    color: 'white',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
    marginTop: 0,
    marginBottom: '10px',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  label: {
    marginBottom: '5px',
    color: '#aaa',
  },
  textarea: {
    width: '90%',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    backgroundColor: '#444',
    color: 'white',
    resize: 'none',
    height: '60px',
    textAlign: 'center',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  submitButton: {
    margin: '0px 0',
    padding: '10px 20px',
    backgroundColor: '#525252',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    color: 'white',
    fontSize: '14px',
    width: '100%',
    maxWidth: '100px',
  },
  cancelButton: {
    margin: '10px 0',
    padding: '10px 20px',
    backgroundColor: 'rgb(255, 58, 58)',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    color: 'white',
    fontSize: '14px',
    width: '100%',
    maxWidth: '100px',
    marginBottom: '0px'
  },
  thankYouMessage: {
    textAlign: 'center',
  },
  closeButton: {
    margin: '10px 0',
    padding: '10px 20px',
    backgroundColor: '#525252',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    color: 'white',
    fontSize: '14px',
    maxWidth: '100px',
  },
};

ReportPollModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ReportPollModal;
