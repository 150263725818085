// CSVViewer.js
import React, { useState, useEffect } from 'react';
import './CSVViewer.css';

function CSVViewer({ userId }) {
  const [clickData, setClickData] = useState([]);

  useEffect(() => {
    const fetchClickData = async () => {
      try {
        const response = await fetch(`/api/user/${userId}/ad-clicks`);
        const data = await response.json();
        if (response.ok) {
          setClickData(data);
        } else {
          console.error('Failed to fetch click data:', data.message);
        }
      } catch (error) {
        console.error('Error fetching click data:', error);
      }
    };

    fetchClickData();
  }, [userId]);

  return (
    <div className="csv-viewer">
      <h3>Ad Click History</h3>
      <table className="csv-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Poll Question</th>
            <th>Click Value</th>
          </tr>
        </thead>
        <tbody>
          {clickData.length > 0 ? (
            clickData.map((click, index) => (
              <tr key={index}>
                <td>{new Date(click.date).toLocaleString()}</td>
                <td>{click.question}</td>
                <td>${parseFloat(click.clickValue.$numberDecimal).toFixed(2)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No click data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default CSVViewer;
