import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Poll from './Poll';
import '../App.css';
import logger from './logger';


const ConnectedPoll = ({ isLoggedIn, userId, username }) => {
  const { hash } = useParams();
  const [connectedPoll, setConnectedPoll] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPollIndex, setCurrentPollIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isComplete, setIsComplete] = useState(false); // State to track if the sequence is complete

  useEffect(() => {
    fetch(`/connected-polls/hash/${hash}`)
      .then(response => response.json())
      .then(data => {
        setConnectedPoll(data.connectedPoll);
        setLoading(false);
      })
      .catch(error => {
        logger.error('Error fetching connected poll:', error);
        setLoading(false);
      });
  }, [hash]);

  const handleVoteSubmit = () => {
    if (currentPollIndex < connectedPoll.pollIds.length - 1) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentPollIndex(prevIndex => prevIndex + 1);
        setIsTransitioning(false);
      }, 100); // Timing for the fade effect
    } else {
      // If the last poll has been voted on, mark the sequence as complete
      setIsComplete(true);
      
      // Hide the overlay after the fade-out completes
      setTimeout(() => {
        setIsComplete(false);
      }, 2000); // This corresponds to 1s fade-in + 2s wait + 1s fade-out
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!connectedPoll) {
    return <div>Connected poll not found</div>;
  }

  const currentPollNumber = currentPollIndex + 1;
  const totalPolls = connectedPoll.pollIds.length;

  return (
    <div className="ConnectedPollWrapper">
      <div className="ConnectedPoll">
        <div className="Poll-item">
          <div className="poll-number-display" style={{ textAlign: 'center', marginBottom: '20px' }}>
            {`${currentPollNumber} / ${totalPolls}`}
          </div>
          {connectedPoll.pollIds.map((poll, index) => (
            <div
              key={poll._id}
              className={`ConnectedPoll-item ${index === currentPollIndex ? 'active' : ''} ${isTransitioning && index === currentPollIndex ? 'fade-out' : ''}`}
              style={{
                zIndex: connectedPoll.pollIds.length - index,
                display: index === currentPollIndex || index === currentPollIndex + 1 ? 'block' : 'none',
              }}
            >
              <Poll
                pollId={poll._id}
                question={poll.question}
                labels={poll.labels}
                logo={poll.logo || "/logo-poll.png"}
                logoFill={poll.logoFill}
                votes={poll.votes}
                hash={poll.hash}
                creatorUsername={poll.creatorUsername}
                isLoggedIn={isLoggedIn}
                userId={userId}
                username={username}
                voteAuthenticationMethod={poll.voteAuthenticationMethod}
                confidenceLabels={poll.confidenceLabels}
                onVoteSubmit={handleVoteSubmit}
                imageAction={poll.imageAction}
                roundedCorners={poll.roundedCorners}
                startDate={poll.startDate}
                endDate={poll.endDate}
                scheduledPoll={poll.scheduledPoll}
                isConnectedPoll={true}
                totalVotes={poll.totalVotes}
              />
            </div>
          ))}

          {/* Overlay for completion message */}
          {isComplete && (
            <div className="ConnectedPollCompleteOverlay">
              <div className="ConnectedPollCompleteMessage">
                Connected Poll Complete
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectedPoll;
