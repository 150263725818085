import React from 'react';
import logger from './logger';


const EmbedCodeGenerator = ({ pollId, hash }) => {
  const embedCode = `
    <iframe id="opinari-embed-iframe" src="${window.location.origin}/embed/${hash}" frameBorder="0" style="width: 450px; min-height: 800px; border-radius: 8px; background-color: transparent;"></iframe>
    <style>
      #opinari-embed-iframe {
        width: 450px;
        min-height: 800px;
        overflow: hidden;
        border-radius: 8px;
        background-color: transparent;
      }
    </style>
  `;

  const handleCopyEmbedCode = () => {
    navigator.clipboard.writeText(embedCode);
    alert('Embed code copied to clipboard!');
  };

  return (
    <div>
      <textarea
        value={embedCode}
        readOnly
        rows="4"
        cols="50"
        className="embed-code-textarea"
      />
      <button onClick={handleCopyEmbedCode} className="tools-button">Copy Embed Code</button>
    </div>
  );
};

export default EmbedCodeGenerator;
