// ImageModal.js

import React from 'react';
import '../App.css';
import logger from './logger';


const ImageModal = ({ isOpen, onClose, imageUrl }) => {
  if (!isOpen) return null;

  return (
    <div className="Modal-overlay" onClick={onClose}>
      <div className="Modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button> {/* Use the same close button */}
        <img src={imageUrl} alt="Expanded view" className="image-modal-img" />
      </div>
    </div>
  );
};

export default ImageModal;
