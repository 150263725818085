import React from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import './CustomTooltip.css';
import logger from './logger';
import { hsvaToHslaString } from '@uiw/color-convert';

const PollAreaChart = ({ votes, labels, isLegacyPoll }) => {
  const getRotationOffset = (numOptions) => {
    switch (numOptions) {
      case 2: return 0;
      case 3: return 208;
      case 4: return 222;
      case 5: return 210;
      case 6: return 565;
      case 7: return 540;
      case 8: return 550;
      case 9: return 550;
      case 10: return 555;
      case 11: return 555;
      case 12: return 555;
      default: return 0;
    }
  };

  const classifyColor = (hue) => {
    if (labels.length === 3 && isLegacyPoll) {
      // Legacy poll logic remains unchanged
      if (hue >= 60 && hue <= 180) {
        return labels[0];
      } else if (hue > 300 || hue <= 60) {
        return labels[2];
      } else if (hue > 180 && hue <= 300) {
        return labels[1];
      } else {
        return 'Mixed Feelings';
      }
    } else {
      // Adjusted non-legacy poll logic
      const numOpinions = labels.length;
      const segmentSize = 360 / numOpinions;
      const initialOffset = -segmentSize / 2;

      // Use the getRotationOffset function for proper rotation
      const rotationOffset = getRotationOffset(numOpinions);

      // Adjust the hue to account for rotation and offset
      const adjustedHue = (hue + rotationOffset - initialOffset + 360) % 360;
      const optionIndex = Math.floor(adjustedHue / segmentSize) % numOpinions;

      return labels[optionIndex];
    }
  };

  const generateLabelColors = () => {
    const numLabels = labels.length;
    const colors = {};
  
    if (numLabels === 3) {
      // Old logic for exactly 3 options
      const segmentSize = 360 / numLabels;
      const initialOffset = 120;
  
      labels.forEach((label, index) => {
        const baseHue = (index * segmentSize + initialOffset) % 360;
        const colorHsl = `hsla(${baseHue}, 100%, 50%, 0.7)`;
        const strokeColor = `hsla(${baseHue}, 100%, 30%, 1)`;
        colors[label] = {
          fill: colorHsl,
          stroke: strokeColor,
        };
      });
    } else {
      // New logic for 2 options or more than 3 options
      const segmentSize = 360 / numLabels;
      const initialOffset = -segmentSize / 2;
      const rotationOffset = getRotationOffset(numLabels);
  
      labels.forEach((label, index) => {
        // Center of each segment
        const adjustedHue = (index * segmentSize + segmentSize / 2 + 360) % 360;
        // Reverse calculation to get base hue
        const baseHue = (adjustedHue - rotationOffset + initialOffset + 360) % 360;
  
        const colorHsl = `hsla(${baseHue}, 100%, 50%, 0.7)`;
        const strokeColor = `hsla(${baseHue}, 100%, 30%, 1)`;
        colors[label] = {
          fill: colorHsl,
          stroke: strokeColor,
        };
      });
    }
  
    return colors;
  };
  

  const labelColors = generateLabelColors();

  // Determine the step based on the number of votes
  let step = 1;
  const totalVotes = votes.length;

  if (totalVotes > 1000000) {
    step = 1000;
  } else if (totalVotes > 100000) {
    step = 100;
  } else if (totalVotes > 10000) {
    step = 10;
  } else if (totalVotes > 1000) {
    step = 10;
  } else if (totalVotes > 400) {
    step = 4;
  } else if (totalVotes > 100) {
    step = 2;
  }

  // Initialize cumulative counts for each label
  const cumulativeCounts = {};
  labels.forEach(label => {
    cumulativeCounts[label] = 0;
  });

  const data = votes
    .filter((_, index) => index % step === 0)
    .map((vote, index, filteredVotes) => {
      if (!vote || !vote.createdAt) return null;
      const totalDisplayedVotes = filteredVotes.length;
      const cumulativeTotalVotes = index + 1;

      const voteLabel = classifyColor(vote.choice);

      cumulativeCounts[voteLabel] = (cumulativeCounts[voteLabel] || 0) + 1;

      const percentages = {};

      labels.forEach(label => {
        const count = cumulativeCounts[label] || 0;
        percentages[label] = (count / cumulativeTotalVotes) * 100;
      });

      return {
        syntheticTimestamp: (index / (totalDisplayedVotes - 1)) * 100,
        ...percentages
      };
    })
    .filter(item => item !== null);

  // Ensure the graph fills correctly when there is only one vote
  if (data.length === 1) {
    data.push({
      syntheticTimestamp: 100,
      ...data[0],
    });
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const actualIndex = Math.round(label * (totalVotes / step - 1) / 100);
      if (votes[actualIndex]) {
        const originalTimestamp = votes[actualIndex].createdAt;
        const formattedTimestamp = new Date(originalTimestamp).toLocaleString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true
        });

        return (
          <div className="chart-tooltip-wrapper">
            <div className="chart-tooltip">
              <p className="chart-tooltip-timestamp">{`Time: ${formattedTimestamp}`}</p>
              <div className="chart-tooltip-stats">
                {labels.map((labelName, i) => (
                  <p key={i} className="chart-tooltip-opinion">{`${labelName}: ${Math.round(payload[i].value)}%`}</p>
                ))}
              </div>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <ResponsiveContainer width="95%" height={200} className="percent-area-chart">
        <AreaChart data={data} margin={{ top: 10, right: 10, left: 0, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="syntheticTimestamp"
            name="Time"
            domain={[0, 100]}
            tickFormatter={(tick) => {
              const actualIndex = Math.round(tick * (totalVotes / step - 1) / 100);
              return votes[actualIndex] ? new Date(votes[actualIndex].createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '';
            }}
            type="number"
            style={{ fontSize: '12px' }}
            tick={{ dy: 10, fill: '#999' }}
          />
          <YAxis
            domain={[0, 100]}
            tickFormatter={(tick) => `${Math.round(tick)}%`}
            style={{ fontSize: '12px' }}
            tick={{ fill: '#808080' }}
            tickCount={6}
          />
          <Tooltip content={<CustomTooltip />} />
          {labels.map((label, index) => (
            <Area
              key={label}
              type="monotone"
              dataKey={label}
              stackId="1"
              stroke={labelColors[label].stroke}
              fill={labelColors[label].fill}
              name={label}
              fillOpacity={0.98}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PollAreaChart;
