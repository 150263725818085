import React, { useState } from 'react';
import './Advertisements.css';
import Ad1 from '../assets/Ad.png';
import Ad2 from '../assets/Ad2.png';
import Ad3 from '../assets/Ad3.png';

const Advertisements = ({ pollId, pollHash, opinionText, category, question, city }) => {
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080';
  const [clickedAds, setClickedAds] = useState([false, false, false]);
  const adImages = [Ad1, Ad2, Ad3];

  const handleAdClick = async (index) => {
    // Optimistically update the UI
    setClickedAds((prev) => {
      const newClickedAds = [...prev];
      newClickedAds[index] = true;
      return newClickedAds;
    });

    const randomRevenue = (Math.random() * (1.50 - 0.35) + 0.35).toFixed(2);
    const pollUrl = `${apiUrl}/poll/${pollHash}`;
    const apiKey = process.env.REACT_APP_AD_PARTNER_API_KEY;

    try {
      const response = await fetch(`${apiUrl}/api/webhook/ad-click`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
        body: JSON.stringify({
          url: pollUrl,
          clickValue: randomRevenue,
          question: question,
        }),
      });

      if (!response.ok) throw new Error('Failed to record ad click');
      console.log('Ad click recorded and revenue update request sent successfully');
    } catch (error) {
      console.error('Error recording ad click:', error);

      // Revert the optimistic update in case of error
      setClickedAds((prev) => {
        const newClickedAds = [...prev];
        newClickedAds[index] = false;
        return newClickedAds;
      });
    }

    // Reset the clicked state after some time (optional)
    setTimeout(() => {
      setClickedAds((prev) => {
        const newClickedAds = [...prev];
        newClickedAds[index] = false;
        return newClickedAds;
      });
    }, 2000);
  };

  return (
    <div className="advertisements-container">

      {/* Display the query info at the top */}
      <div className="query-info">
        Sample Query: {opinionText} - {category}
      </div>

      {/* Render the ad images */}
      <div className="ad-boxes">
        {adImages.map((adImage, index) => (
          <div
            key={index}
            className={`ad-box ${clickedAds[index] ? 'clicked' : ''}`}
            onClick={() => handleAdClick(index)}
          >
            <img src={adImage} alt={`Ad ${index + 1}`} />
            {clickedAds[index] && <div className="overlay">Example Ad Click $</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Advertisements;
