import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import logger from './logger';

const UpgradeModal = ({ onClose, feature, currentPlan, usage, isLoggedIn }) => {  // Add `isLoggedIn` as a prop
  const navigate = useNavigate();

  logger.log('Feature:', feature);
  logger.log('Current Plan:', currentPlan);

  const handleUpgradeClick = () => {
    if (!isLoggedIn) {  // Check if the user is not logged in
      navigate('/signup');  // Navigate to the signup page
      return;
    }

    const nextPlanMap = {
        Free: 'Pro',
        Pro: 'Elite',
        Elite: 'Unlimited',
        Unlimited: 'Unlimited', // No further upgrade available
    };

    const nextPlan = nextPlanMap[currentPlan];

    navigate('/pricing', { state: { selectedPlan: nextPlan } });
  };

  const featureMessages = {
    pollLimit: {
      Free: `You've reached the limit of 2 polls. Upgrade to create more polls.`,
      Pro: `You've reached the limit of 10 polls. Upgrade to create more polls.`,
      Elite: `You've reached the limit of 100 polls. Upgrade to create more polls.`,
      Unlimited: `You've reached the limit of 1000 polls. Upgrade to create more polls. If you require more, please contact us :)`,
    },
    customImage: {
      Free: 'Upgrade to Pro to use custom images.',
      Pro: 'Upgrade to Elite to use custom images.',
      Elite: 'Upgrade to Unlimited to use custom images.',
      Unlimited: 'You already have the Unlimited plan, which supports custom images.',
    },
    customLabels: {
      Free: 'Upgrade to Pro to use custom labels.',
      Pro: 'Upgrade to Elite to use custom labels.',
      Elite: 'Upgrade to Unlimited to use custom labels.',
      Unlimited: 'You already have the Unlimited plan, which supports custom labels.',
    },
    csvDownload: {
      Free: 'Upgrade to Pro to download CSV data.',
      Pro: 'Upgrade to Elite to download CSV data.',
      Elite: 'Upgrade to Unlimited to download CSV data.',
      Unlimited: 'You already have the Unlimited plan, which supports CSV downloads.',
    },
    pdfReport: {
      Free: `You've generated 1/1 PDF report this month. Upgrade to generate more.`,
      Pro: `You've generated 20/20 PDF reports this month. Upgrade to generate more.`,
      Elite: `You've generated 60/60 PDF reports this month. Upgrade to generate more.`,
      Unlimited: `You've generated 120/120 PDF reports this month. Upgrade to generate more.`,
    },
    linkRedirection: {
      Free: 'Upgrade to Pro to use link redirection.',
      Pro: 'Upgrade to Elite to use link redirection.',
      Elite: 'Upgrade to Unlimited to use link redirection.',
      Unlimited: 'You already have the Unlimited plan, which supports link redirection.',
    },
    reCaptcha: {
      Free: 'Upgrade to Pro to use reCAPTCHA.',
      Pro: 'Upgrade to Elite to use reCAPTCHA.',
      Elite: 'Upgrade to Unlimited to use reCAPTCHA.',
      Unlimited: 'You already have the Unlimited plan, which supports reCAPTCHA.',
    },
    schedule: {
      Free: 'Upgrade to Pro to schedule polls.',
      Pro: 'Upgrade to Pro to schedule polls.',
      Elite: 'Upgrade to Pro to schedule polls.',
      Unlimited: 'Upgrade to Pro to schedule polls.',
    },
  };

  // New message for users who are not logged in
  const notLoggedInMessage = 'Sign up to use advanced features!';

  const message = isLoggedIn
    ? featureMessages[feature]?.[currentPlan || 'Free']  // Existing logic for logged-in users
    : notLoggedInMessage;  // New message for non-logged-in users

  if (!message) {
    logger.error(`No message found for feature: ${feature} with currentPlan: ${currentPlan}`);
  }

  return (
    <div className="Modal-overlay">
      <div className="Modal-content">
        <button className="modal-close" onClick={onClose}>×</button>
        <h2>{isLoggedIn ? 'Upgrade Your Plan' : 'Advanced Feature'}</h2> {/* Change title based on login status */}
        <p>{message}</p>
        <button className="Auth-modal-button" onClick={handleUpgradeClick}>
          {isLoggedIn ? 'Upgrade Now' : 'Sign Up'}  {/* Button text changes based on login status */}
        </button>
      </div>
    </div>
  );
};

export default UpgradeModal;
