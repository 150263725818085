import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Poll from './Poll';
import logger from './logger';
import PollSuccessModal from './PollSuccessModal';
import PoweredBy from '../assets/PoweredBy.png';


const StandalonePoll = ({ isLoggedIn, userId, username }) => {
  const { hash } = useParams();
  const location = useLocation(); // To access query parameters
  const [poll, setPoll] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State for showing success modal
  const navigate = useNavigate();


// Function to scroll to the bottom slowly and trigger button animation
const scrollToBottom = () => {
  const targetPosition = document.documentElement.scrollHeight - window.innerHeight; // Corrected target position
  const startPosition = window.scrollY; // Current scroll position
  const distance = targetPosition - startPosition; // Distance to scroll
  const duration = 800; // Scroll duration in milliseconds
  let startTime = null;

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animation = (currentTime) => {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
    window.scrollTo(0, run);

    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    } else {
      // Ensure the scroll has finished completely
      window.scrollTo(0, targetPosition);

      // Trigger the button animation as soon as scroll is done
      const buildYoursButton = document.querySelector('.build-yours-button');
      if (buildYoursButton) {
        buildYoursButton.classList.add('animate-pop'); // Start pop-out animation
        setTimeout(() => {
          buildYoursButton.classList.remove('animate-pop'); // End pop-out animation after it's done
        }, 300); // 0.3s to match the CSS animation timing
      }
    }
  };

  requestAnimationFrame(animation);
};




const poweredByWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
};

const poweredByStyle = {
  height: '30px', // Set the width of the powered by image
  display: 'block',
  marginTop: '10px'
};



  useEffect(() => {
    logger.log('Fetching poll with hash:', hash);
    fetch(`/polls/${hash}`)
      .then(response => response.json())
      .then(data => {
        logger.log('Poll data:', data);
        setPoll(data.poll);
        setLoading(false);

        // Check if we have the 'created' query param to show the success modal
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('created') === 'true') {
          setShowSuccessModal(true); // Show success modal if poll was just created
        }
      })
      .catch(error => {
        logger.error('Error fetching poll:', error);
        setLoading(false);
      });
  }, [hash, location.search]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!poll) {
    return <div>This poll has been deleted.</div>;
  }

  return (
    <div className="StandalonePollWrapper">
    {showSuccessModal && poll && (
      <PollSuccessModal
        pollLink={`${window.location.origin}/poll/${hash}`} // Use full URL
        onClose={() => setShowSuccessModal(false)}
        isTemporary={poll.isTemporary}
      />
    )}
    <div className="StandalonePoll" style={{ padding: '20px' }}>
      <div className="Poll-item">
        <Poll
          creatorId={poll.creator._id} // Extract _id from the creator object
          pollId={poll._id}
          question={poll.question}
          labels={poll.labels}
          logo={poll.logo || "/logo-poll.png"} // Pass the logo from the poll object or use the default if not available
          logoFill={poll.logoFill}
          votes={poll.votes}
          hash={hash}
          creatorUsername={poll.creatorUsername}
          isLoggedIn={isLoggedIn}
          userId={userId}
          username={username}
          voteAuthenticationMethod={poll.voteAuthenticationMethod}
          confidenceLabels={poll.confidenceLabels}
          imageAction={poll.imageAction}
          roundedCorners={poll.roundedCorners}
          startDate={poll.startDate}
          endDate={poll.endDate}
          scheduledPoll={poll.scheduledPoll}
          useCaptcha={poll.useCaptcha}
          totalVotes={poll.totalVotes}
          isTemporary={poll.isTemporary}
          scrollToBottom={scrollToBottom} // Pass scroll function to Poll
          isLegacyPoll={poll.isLegacyPoll}
          isAdvertising={poll.isAdvertising}
          category={poll.category}
        />

        
      <a href="/" style={{ textDecoration: 'none' }}>
  <div style={poweredByWrapperStyle}>
    <img 
      src={PoweredBy}
      alt="Powered by Opinari" 
      style={poweredByStyle}
    />
  </div>
</a>

      </div>
      {/* {!isLoggedIn && (
  <div
    className="build-yours-button"
    onClick={() => navigate('/create-poll')}
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      padding: '10px',
      marginTop: '20px',
      borderRadius: '10px',
      backgroundColor: '#1a73e8',
      color: 'white',
      fontSize: '16px',
      textAlign: 'center'
    }}
  >
    <img
      src={'/logo512.png'} // Replace with the actual path to your image
      alt="Build Icon"
      style={{ marginRight: '10px', width: '20px', height: '20px' }}
    />
    Build Yours
  </div>
)} */}
    </div>
  </div>
);
};

export default StandalonePoll;
