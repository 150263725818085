import React, { useState } from 'react';
import './SignUpPage.css';

const Apply = ({ userId, userEmail }) => {
  const [socialMediaHandles, setSocialMediaHandles] = useState(['']);
  const [selectedAudienceSize, setSelectedAudienceSize] = useState('');
  const [selectedEngagementRate, setSelectedEngagementRate] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [submitted, setSubmitted] = useState(false); // New state for submission status

  const handleAddSocialField = () => {
    if (socialMediaHandles.length < 6) {
      setSocialMediaHandles([...socialMediaHandles, '']);
    }
  };

  const handleSocialMediaChange = (index, value) => {
    const updatedHandles = [...socialMediaHandles];
    updatedHandles[index] = value;
    setSocialMediaHandles(updatedHandles);
  };

  const handleDeleteSocialField = (index) => {
    const updatedHandles = socialMediaHandles.filter((_, i) => i !== index);
    setSocialMediaHandles(updatedHandles);
  };

  const handleAudienceSizeClick = (size) => {
    setSelectedAudienceSize(size);
  };

  const handleEngagementRateClick = (rate) => {
    setSelectedEngagementRate(rate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      userId,
      userEmail,
      socialMediaHandles,
      selectedAudienceSize,
      selectedEngagementRate,
      additionalInfo,
    };

    try {
      const response = await fetch('/api/send-application-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        console.log('Application submitted successfully.');
        setSubmitted(true); // Set submitted to true on successful submission
      } else {
        console.error('Failed to submit application.');
      }
    } catch (error) {
      console.error('Error submitting application:', error);
    }
  };

  return (
    <div className="apply__container">
      <div className="apply-page__form-section">
        <div className="signup-page__form-wrapper">
          {!submitted ? ( // Conditionally render form or thank-you message
            <>
              <h2>Apply to Get Monetized</h2>
              <form onSubmit={handleSubmit}>
                {/* Social Media Handles */}
                <div className="signup-page__form-group">
                  <label className="signup-page__label">Social Media Links (from largest to smallest following)</label>
                  {socialMediaHandles.map((handle, index) => (
                    <div key={index} className="social-media-field">
                      <input
                        type="text"
                        placeholder={`Social Link ${index + 1}`}
                        value={handle}
                        onChange={(e) => handleSocialMediaChange(index, e.target.value)}
                        className="signup-page__input"
                        style={{ marginBottom: '10px' }}
                      />
                      {socialMediaHandles.length > 1 && (
                        <button
                          type="button"
                          onClick={() => handleDeleteSocialField(index)}
                          className="delete-social-button"
                        >
                          ×
                        </button>
                      )}
                    </div>
                  ))}
                  {socialMediaHandles.length < 6 && (
                    <button
                      type="button"
                      onClick={handleAddSocialField}
                      className="signup-page__custom-submit-button"
                      style={{ marginTop: '10px' }}
                    >
                      + Add Social Media
                    </button>
                  )}
                </div>

                {/* Estimated Audience Size */}
                <div className="signup-page__form-group">
                  <label className="signup-page__label">Estimated Audience Size</label>
                  <div className="button-group">
                    {['1k-10k', '10k-50k', '50k-200k', '200k-1M', '1M-10M', '10M+'].map((size) => (
                      <button
                        key={size}
                        type="button"
                        className={`signup-page__custom-submit-button ${selectedAudienceSize === size ? 'active' : ''}`}
                        onClick={() => handleAudienceSizeClick(size)}
                      >
                        {size}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Estimated Engagement Rate */}
                <div className="signup-page__form-group">
                  <label className="signup-page__label">Estimated Engagement Rate</label>
                  <div className="button-group">
                    {['<1%', '1-3%', '3-5%', '5-10%', '10-15%', '15+'].map((rate) => (
                      <button
                        key={rate}
                        type="button"
                        className={`signup-page__custom-submit-button ${selectedEngagementRate === rate ? 'active' : ''}`}
                        onClick={() => handleEngagementRateClick(rate)}
                      >
                        {rate}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Additional Info */}
                <div className="signup-page__form-group">
                  <label className="signup-page__label">Additional Info (Optional)</label>
                  <textarea
                    placeholder="Add any additional information here..."
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                    className="signup-page__textarea"
                    rows="4"
                  />
                </div>

                {/* Submit Button */}
                <button type="submit" className="signup-page__custom-submit-button">
                  Submit Application
                </button>
              </form>
            </>
          ) : (
            <div className="thank-you-message">
              <h2>Thank You for Applying!</h2>
              <p>
                Your application has been submitted successfully. You will hear back from us within the next 24 hours. 
                Please check your email for an update on your application status.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Apply;
