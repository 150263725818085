import React from 'react';
import './FAQs.css';
import DesktopBG from '../assets/bg.png';
import MobileBG from '../assets/bg.png';
import { useNavigate, Link } from 'react-router-dom';


// Import PDFs from assets
import Pdf1 from '../assets/Howtocreateapoll_PDF.pdf';
import Pdf2 from '../assets/Howtodeletepolls_PDF.pdf';
import Pdf3 from '../assets/Howtosharepolls_PDF.pdf';
import Pdf4 from '../assets/Howtodownloadpolldata_PDF.pdf';
import Pdf5 from '../assets/Howtogenerateadatareportonline_PDF.pdf';
import Pdf6 from '../assets/Howtoresetyourpassword_PDF.pdf';
import Pdf7 from '../assets/HowdoISubscribetootherOPINARIusers_PDF.pdf';
import Pdf8 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf9 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf11 from '../assets/Howtocreateconnectedpolls_PDF.pdf';
import Pdf12 from '../assets/Howtoresetyourpassword_PDF.pdf';
import Pdf13 from '../assets/HowdoISubscribetootherOPINARIusers_PDF.pdf';
import Pdf14 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf15 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf16 from '../assets/Howtocreateconnectedpolls_PDF.pdf';
import Pdf17 from '../assets/Howtocreateconnectedpolls_PDF.pdf';
import Pdf10 from '../assets/Howtocreateconnectedpolls_PDF.pdf';




import Logo from '../assets/logo.png';
import HeroImg from '../assets/Hero.png';
import YouTubeIcon from '../assets/youtube.png';
import FacebookIcon from '../assets/facebook.png';
import InstagramIcon from '../assets/instagram.png';
import LinkedInIcon from '../assets/linkedin.png';
import PinterestIcon from '../assets/pinterest.png';
import RedditIcon from '../assets/reddit.png';
import SnapchatIcon from '../assets/snapchat.png';
import TikTokIcon from '../assets/tiktok.png';
import XIcon from '../assets/x.png';
import OpinionsImg from '../assets/opinions.png';
import FlexibilityImg from '../assets/flexibility.png';
import HandledImg from '../assets/handled.png';

const FAQPage = () => {
  const isDesktop = window.innerWidth >= 768;

  const backgroundStyle = {
    backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  };

  const openPdf = (pdfSrc) => {
    window.open(pdfSrc, '_blank');
  };

  const faqItems = [
    { pdf: Pdf11, question: "How do I make money?", description: "To earn with OPINARI, apply to monetize your polls. Once approved, create relevant polls and encourage followers to vote. When followers vote, they see targeted ads; each ad click earns you a commission!" },
    { pdf: Pdf12, question: "What is the Rev share?", description: "OPINARI operates on a commission-based model, with earnings generated through CPC ad clicks. After our ad partners take their share (fluctuates between 15-35%), OPINARI shares the remaining revenue with influencers on a 50/50 basis." },
    { pdf: Pdf13, question: "How do I get paid?", description: "All commission payments are processed via PayPal and are made in alignment with our ad partners’ payout schedules. Users receive commission payments only after OPINARI receives funds from ad partners." },
    { pdf: Pdf14, question: "How do I monetize my polls?", description: "To monetize your polls, request a monetization review from the OPINARI team. Once approved, you can start earning! Each poll also undergoes a quick review to meet ad provider policies, typically within minutes but occasionally up to 1–2 business days." },
    { pdf: Pdf15, question: "How long does it take for polls to be approved?", description: "Typically within minutes but occasionally up to 1–2 business days." },
    { pdf: Pdf16, question: "Why did my poll get disapproved to run ads?", description: "Your poll was disapproved as it didn’t meet our ad partners' policies or community guidelines. You may appeal the review if you'd like." },
    { pdf: Pdf17, question: "Why OPINARI?", description: "OPINARI brings influencers new revenue through PPC (pay-per-click) advertising that’s relevant to each post and displayed based on their followers’ opinions from their poll responses." },
    { pdf: Pdf1, question: "How do I make a poll?", description: "Navigate to the profile page by selecting your username in the top right hand corner. Once in the profile section simply click the plus button just below profile picture and bio." },
    { pdf: Pdf2, question: "How to delete polls?", description: "Select the poll you want to delete and then scroll to the bottom of the poll and hit the \"Delete\" poll button." },
    { pdf: Pdf3, question: "How to share polls?", description: "Click the share icon in the top right hand corner of the poll you want to share. The arrow icon." },
    { pdf: Pdf4, question: "How to download poll data?", description: "Click the \"Download Data\" button within the tools dropdown located in the top left hand corner of the poll. The gear icon." },
    { pdf: Pdf5, question: "How do I generate a report?", description: "Click the \"Data Report\" button within the tools dropdown located in the top left hand corner of the poll. The gear icon." },
    { pdf: Pdf6, question: "How do I reset my password?", description: "Click the \"Login Button\" in the top right hand corner of the home page. Click the \"Forgot Password\" button below login info. Enter in account email and hit \"Send Reset Link\". Follow the instructions within the password reset email." },
    { pdf: Pdf7, question: "How do I follow other OPINARI users?", description: "Navigate to the profile of the person you wish to follow and select the bell icon in the top left hand corner of their profile page. Once you hit the bell icon you will receive email notifications whenever that individual has created a new poll." },
    { pdf: Pdf8, question: "How do I report polls?", description: "Click the three dots in the top left hand corner of the poll you wish to report. Follow the instructions within the report poll window. Once submitted our team will review the poll and take further action." },
    { pdf: Pdf9, question: "How do I unsubscribe to emails?", description: "Click the three dots in the top left hand corner of the poll you wish to report. Follow the instructions within the report poll window. Once submitted our team will review the poll and take further action." },
    { pdf: Pdf10, question: "How do I create a connected poll?", description: "Navigate to your profile page. Click \"connected polls\" on the far right side below your bio. Hit \"Create new collection\" and follow the prompted instructions." },
   

  ];

  return (
    <div className="faq-page-wrapper">
      <div style={backgroundStyle}></div>
      <div className="faq-page">
        <section className="faq-section-description">
          <h1 className="faq-title">FAQ</h1>
          <p className="faq-intro">
            What can we help you find? For step-by-step guides, click on your desired question, and a PDF will open in a new tab!
          </p>
        </section>

        <div className="faq-grid">
          {faqItems.map((item, index) => (
            <div 
              key={index} 
              className="faq-item"
            >
              <div className="faq-item-content">
                <h3 className="faq-item-title">{`FAQ ${index + 1}: ${item.question}`}</h3>
                <p className="faq-item-description">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo-section">
            <img src={Logo} alt="Logo" className="footer-logo" />
          </div>

          <div className="footer-links">
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/contact">Contact</a></li>
              <li><a href="/FAQs">FAQs</a></li>
            </ul>
          </div>

          <div className="footer-socials">
            <img src={YouTubeIcon} alt="YouTube" />
            <img src={FacebookIcon} alt="Facebook" />
            <img src={InstagramIcon} alt="Instagram" />
            <img src={LinkedInIcon} alt="LinkedIn" />
            <img src={TikTokIcon} alt="TikTok" />
          </div>
        </div>

        <div className="footer-bottom">
          <p>&copy; 2024 OPINARI. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default FAQPage;