import React from 'react';
import './Privacy.css';
import DesktopBG from '../assets/desktop-bg-login.jpg';
import MobileBG from '../assets/mobile-bg-login.jpg';

const Terms = () => {
    const isDesktop = window.innerWidth >= 768;

    const backgroundStyle = {
        backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
    };

    return (

        <div className="TermsPage-container">
          <h1 className="TermsPage-heading">Terms of Service</h1>
          <section className="TermsPage-section">
            <h2 className="TermsPage-subheading">1. Introduction</h2>
            <p className="TermsPage-paragraph">
              These terms and conditions ("Terms and
              Conditions") are an agreement between Opinari
              Technologies (”Opinari”) and you ("you" and
              "your") governing your use of Opinari's products,
              services, and websites (collectively, the
              "Services"). By accessing or using the Services,
              you agree to be bound by these Terms and
              Conditions and our Privacy Policy. If you are using
              the Services on behalf of an organization, you are
              agreeing to these Terms and Conditions for that
              organization and representing that you have the
              authority to bind that organization to these Terms
              and Conditions. In that case, "you" and "your" will
              refer to that organization.
            </p>
          </section>
          <section className="TermsPage-section">
            <h2 className="TermsPage-subheading">2. Services</h2>
            <p className="TermsPage-paragraph">
              Opinari's Services may include various products,
              applications, websites, and services, including but
              not limited to texting, phone calling, data analytics,
              and other communication services ("Products").
              Opinari reserves the right to modify or discontinue
              any of the Services, or any part thereof, at any
              time. You acknowledge and agree that Opinari
              shall not be liable to you or any third party in the
              event that Opinari exercises its right to modify or
              discontinue any of the Services.
            </p>
          </section>
          <section className="TermsPage-section">
            <h2 className="TermsPage-subheading">3. Registration</h2>
            <p className="TermsPage-paragraph">
              In order to access certain features of the Services,
              you may be required to register for an account.
              You agree to provide accurate and complete
              information when creating your account and to
              update your information as necessary to keep it
              accurate and complete. You are solely responsible
              for the activity that occurs on your account and
              you must keep your account password secure.
              You must notify Opinari immediately of any breach
              of security or unauthorized use of your account.
              You may not use another user's account without
              permission.
            </p>
          </section>
          <section className="TermsPage-section">
            <h2 className="TermsPage-subheading">4. Fees and Payment</h2>
            <p className="TermsPage-paragraph">
              You agree to pay all applicable fees for the
              Services in accordance with the fee schedule and
              payment terms in effect at the time the fee is due.
              Opinari reserves the right to change its fee
              schedule and payment terms at any time without
              prior notice. All fees are non-refundable..
            </p>
          </section>
          <section className="TermsPage-section">
            <h2 className="TermsPage-subheading">5. Privacy</h2>
            <p className="TermsPage-paragraph">
              Opinari's Privacy Policy (found at https://Opinari.io/
              privacy-policy.html) describes how we collect, use,
              and share information about you. You consent to
              the collection, use, and sharing of your information
              in accordance with our Privacy Policy. <a href="contact.html">contact us</a>.
            </p>
          </section>
          <section className="TermsPage-section">
            <h2 className="TermsPage-subheading">6. Warranties and Disclaimers</h2>
            <p className="TermsPage-paragraph">
              Opinari makes no representations, warranties, or
              guarantees regarding the accuracy, reliability, or
              completeness of the Services. The Services are
              provided "as is" and "as available" and Opinari
              disclaims all warranties, express or implied,
              including, but not limited to, implied warranties of
              merchantability, fitness for a particular purpose,
              and non-infringement.. <a href="contact.html">contact us</a>.
            </p>
          </section>
          <section className="TermsPage-section">
            <h2 className="TermsPage-subheading">7. Limitation of Liability</h2>
            <p className="TermsPage-paragraph">
              In no event shall Opinari, its officers, directors,
              employees, or agents, be liable for any direct,
              indirect, special, incidental, or consequential
              damages, whether based in contract, tort, or
              otherwise, arising out of or in connection with the
              use of the Services or any other materials or
              services provided to you by Opinari. <a href="contact.html">contact us</a>.
            </p>
          </section>
          <section className="TermsPage-section">
            <h2 className="TermsPage-subheading">8. Indemnification</h2>
            <p className="TermsPage-paragraph">
              You agree to indemnify and hold harmless Opinari,
              its officers, directors, employees, and agents, from
              and against any claims, liabilities, damages,
              losses, and expenses, including, without limitation,
              reasonable attorney's fees and costs, arising out
              of or in any way connected with your access to or
              use of the Services. <a href="contact.html">contact us</a>.
            </p>
          </section>
          <section className="TermsPage-section">
            <h2 className="TermsPage-subheading">9. Miscellaneous</h2>
            <p className="TermsPage-paragraph">
              This agreement is governed by the laws of the
              province of Ontario, Canada, without regard to its
              conflict of law provisions. Any dispute arising out
              of or related to these Terms and Conditions or the
              Services shall be subject to the exclusive
              jurisdiction of the courts of Ottawa, Ontario,
              Canada. You may not assign or transfer these
              Terms and Conditions without the prior written
              approval of Opinari. Opinari's failure to enforce
              any right or provision of these Terms and
              Conditions shall not be deemed a waiver of such
              right or provision. If any provision of these Terms
              and Conditions is held to be invalid or
              unenforceable, the remaining provisions of these
              Terms and Conditions will remain in full force and
              effect.
              This Agreement constitutes the entire agreement
              between you and Opinari with respect to the
              Services and supersedes all prior or
              contemporaneous communications and proposals
              (whether oral, written, or electronic) between you
              and Opinari with respect to the Services.
              These Terms and Conditions were last updated on
              June 1, 2024.
              By signing up for and using Opinari's Services,
              you agree to be bound by these Terms and
              Conditions. <a href="contact.html">contact us</a>.
            </p>
          </section>
        </div>
      );
   
};

export default Terms;