import React, { useState, useEffect, useRef, useCallback } from 'react';
import Wheel from '@uiw/react-color-wheel';
import { hsvaToHex, hsvaToHslaString } from '@uiw/color-convert';
import SubmitButton from './SubmitButton';
import ColorBar from './ColorBar';
import ConfidenceBar from './ConfidenceBar';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from '../animations/vote-animation.json';
import PollAreaChart from './PollAreaChart';
import '../App.css';
import ImageModal from './ImageModal';
import logger from './logger';
import useFitText from './useFitText'; // Make sure the hook is imported


const PreviewPoll = ({
  question,
  labels,
  logo,
  logoFill,
  confidenceLabels,
  imageAction,
  roundedCorners,
}) => {
  const [hsva, setHsva] = useState({ h: 0, s: 0, v: 100, a: 1 });
  const [size, setSize] = useState(400);
  const [opinion, setOpinion] = useState("What's your Opinion?");
  const [votes, setVotes] = useState([]);
  const [showSubmit, setShowSubmit] = useState(false);
  const [wheelActive, setWheelActive] = useState(false);
  const [animationVisible, setAnimationVisible] = useState(false);
  const [alreadyVoted, setAlreadyVoted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const { ref, fontSize } = useFitText(24, 12, [opinion]);


  const DEFAULT_LOGO_URL = '/logo-poll.png';
  const containerRef = useRef(null);
  const colorWheelRef = useRef(null);

  const getRotationOffset = (numOptions) => {
    switch (numOptions) {
      case 2: return 0;
      case 3: return 208;
      case 4: return 222;
      case 5: return 210;
      case 6: return 565;
      case 7: return 540;
      case 8: return 550;
      case 9: return 550;
      case 10: return 555;
      case 11: return 555;
      case 12: return 555;
      default: return 0;
    }
  };
  

  const generateConfidenceColors = () => {
    const numOpinions = labels.length;
    const segmentSize = 360 / numOpinions;
    const initialOffset = -segmentSize / 2; // Align with the builder

    const colors = {};

    labels.forEach((label, index) => {
      const rotationOffset = getRotationOffset(labels.length);

      const baseHue = (index * segmentSize + initialOffset + rotationOffset + 360) % 360;
      colors[label] = { h: baseHue, s: 100, v: 100, a: 1 };
    });

    return colors;
  };

  const confidenceColors = generateConfidenceColors();

  const handleChange = (color) => {
    if (wheelActive) {
      setHsva(color.hsva);
      setBodyBackgroundColor(hsvaToHex(color.hsva));
      setOpinion(mapColorToOpinion(color.hsva));
    }
  };

  const handleActivateWheel = (e) => {
    if (
      !wheelActive &&
      colorWheelRef.current &&
      colorWheelRef.current.contains(e.target) &&
      !isSubmitting
    ) {
      setWheelActive(true);
    }
  };

  const handleMouseUp = useCallback(
    (event) => {
      if (
        wheelActive &&
        colorWheelRef.current &&
        colorWheelRef.current.contains(event.target)
      ) {
        setShowSubmit(true);
      }
    },
    [wheelActive]
  );

  const handleTouchEnd = useCallback(
    (event) => {
      if (
        wheelActive &&
        colorWheelRef.current &&
        colorWheelRef.current.contains(event.target)
      ) {
        setShowSubmit(true);
      }
    },
    [wheelActive]
  );

  const handleSubmit = () => {
    setIsSubmitting(true);
    setWheelActive(false);

    let baseOpinion;
    const h = hsva.h;
    const s = hsva.s;

    if (s === 0) {
      baseOpinion = 'No Opinion';
    } else {
      const numOpinions = labels.length;
      const segmentSize = 360 / numOpinions;
      const initialOffset = -segmentSize / 2;
      const rotationOffset = getRotationOffset(labels.length);


      // Adjust the hue to account for rotation and offset
      const adjustedHue = (h + rotationOffset - initialOffset + 360) % 360;
      const optionIndex = Math.floor(adjustedHue / segmentSize) % numOpinions;
      baseOpinion = labels[optionIndex];
    }

    const opinionIndex = labels.indexOf(baseOpinion);
    const opinionConfidenceLabels =
      confidenceLabels[opinionIndex] || ['Very Low', 'Low', 'Medium', 'High', 'Very High'];
    const numConfidenceLevels = opinionConfidenceLabels.length;
    const stepSize = 100 / numConfidenceLevels;
    const confidenceIndex = Math.min(
      Math.floor(s / stepSize),
      numConfidenceLevels - 1
    );

    const newVote = {
      choice: hsva.h,
      confidence: confidenceIndex,
      saturation: hsva.s,
      createdAt: new Date().toISOString(),
    };

    setVotes((prevVotes) => [...prevVotes, newVote]);
    setBodyBackgroundColorWithFade('', 0.5);
    setShowSubmit(false);
    setAnimationVisible(true);

    setTimeout(() => {
      setAnimationVisible(false);
      setIsSubmitting(false);
    }, 1600);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        wheelActive &&
        colorWheelRef.current &&
        !colorWheelRef.current.contains(event.target)
      ) {
        setWheelActive(false);
        setShowSubmit(false);
        setBodyBackgroundColorWithFade('', 0.5);
      }
    };

    const handleResize = () => {
      const pollItem = document.querySelector('.preview-container');
      if (pollItem) {
        const pollWidth = pollItem.offsetWidth;
        const newSize = Math.min(pollWidth, window.innerHeight) * 0.83;
        setSize(newSize);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleTouchEnd);
    document.addEventListener('mousedown', handleClickOutside);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleTouchEnd);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wheelActive, handleMouseUp, handleTouchEnd]);

  const mapColorToOpinion = (hsva) => {
    const { h, s } = hsva;
    if (s === 0) return 'No Opinion';

    const numOpinions = labels.length;
    const segmentSize = 360 / numOpinions;
    const initialOffset = -segmentSize / 2;

    const rotationOffset = getRotationOffset(labels.length);

    // Adjust the hue to account for rotation and offset
    const adjustedHue = (h + rotationOffset - initialOffset + 360) % 360;
    const optionIndex = Math.floor(adjustedHue / segmentSize) % numOpinions;
    const baseOpinion = labels[optionIndex];

    const opinionIndex = optionIndex;
    const opinionConfidenceLabels =
      confidenceLabels[opinionIndex] || ['Very Low', 'Low', 'Medium', 'High', 'Very High'];
    const numConfidenceLevels = opinionConfidenceLabels.length;

    const stepSize = 100 / numConfidenceLevels;
    const confidenceIndex = Math.min(
      Math.floor(s / stepSize),
      numConfidenceLevels - 1
    );
    const strength = opinionConfidenceLabels[confidenceIndex];

    return `${baseOpinion} ${strength}`;
  };

  const classifyColor = (hue) => {
    const numOpinions = labels.length;
    const segmentSize = 360 / numOpinions;
    const initialOffset = -segmentSize / 2;
    const rotationOffset = getRotationOffset(labels.length);


    // Adjust the hue to account for rotation and offset
    const adjustedHue = (hue + rotationOffset - initialOffset + 360) % 360;
    const optionIndex = Math.floor(adjustedHue / segmentSize) % numOpinions;

    return labels[optionIndex];
  };

  const calculateColorPercentages = (votesArray) => {
    if (!Array.isArray(votesArray)) {
      return [];
    }

    const colorCounts = votesArray.reduce((acc, vote) => {
      const color = hsvaToHslaString({
        h: vote.choice,
        s: vote.saturation,
        v: 100,
        a: 1,
      });
      const classification = classifyColor(vote.choice);
      const opinion = mapColorToOpinion({
        h: vote.choice,
        s: vote.saturation,
        v: 100,
        a: 1,
      });

      if (!acc[classification]) {
        acc[classification] = [];
      }

      acc[classification].push({
        color,
        confidence: vote.confidence,
        timestamp: vote.createdAt,
        opinion,
        hue: vote.choice,
        saturation: vote.saturation,
        classification,
      });
      return acc;
    }, {});

    const totalVotes = votesArray.length;
    const sortedColors = labels
      .map((classification) => {
        if (!colorCounts[classification]) return [];
        return colorCounts[classification]
          .sort((a, b) => a.confidence - b.confidence)
          .map((entry) => ({
            ...entry,
            percentage: (1 / totalVotes) * 100,
          }));
      })
      .flat();

    return sortedColors;
  };

  const colorPercentages = calculateColorPercentages(votes);

  const counts = labels.reduce((acc, label) => {
    acc[label] = votes.filter(
      (vote) => classifyColor(vote.choice) === label
    ).length;
    return acc;
  }, {});

  const calculateConfidencePercentages = (
    votesArray,
    classification,
    baseColor
  ) => {
    const filteredVotes = votesArray.filter(
      (vote) => classifyColor(vote.choice) === classification
    );

    const confidenceCounts = filteredVotes.reduce((acc, vote) => {
      if (!acc[vote.confidence]) {
        acc[vote.confidence] = 0;
      }
      acc[vote.confidence]++;
      return acc;
    }, {});

    const totalVotes = filteredVotes.length;
    const confidencePercentages = [];

    const classificationIndex = labels.indexOf(classification);
    const classificationConfidenceLabels =
      confidenceLabels[classificationIndex] || [
        'Very Low',
        'Low',
        'Medium',
        'High',
        'Very High',
      ];
    const numConfidenceLevels = classificationConfidenceLabels.length;

    for (let i = 0; i < numConfidenceLevels; i++) {
      const count = confidenceCounts[i] || 0;
      const percentage = totalVotes
        ? ((count / totalVotes) * 100).toFixed(1)
        : 0;
      if (percentage > 0) {
        const adjustedColor = computeColor(
          baseColor.h,
          i,
          numConfidenceLevels
        );
        confidencePercentages.push({
          count,
          percentage,
          color: adjustedColor,
          label: classificationConfidenceLabels[i],
        });
      }
    }

    return confidencePercentages;
  };

  const computeColor = (baseHue, confidenceIndex, totalConfidenceLevels) => {
    const saturation = ((confidenceIndex + 1) / totalConfidenceLevels) * 100;
    return hsvaToHslaString({ h: baseHue, s: saturation, v: 100, a: 1 });
  };

  const detectAndReplaceLinks = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          className="poll-link"
        >
          view link
        </a>
      ) : (
        part
      )
    );

    return (
      <>
        &quot;
        {parts}
        &quot;
      </>
    );
  };

  const setBodyBackgroundColor = (color) => {
    document.body.style.transition = '';
    document.body.style.backgroundColor = color;
  };

  const setBodyBackgroundColorWithFade = (color, duration) => {
    document.body.style.transition = `background-color ${duration}s`;
    document.body.style.backgroundColor = color;

    setTimeout(() => {
      document.body.style.transition = '';
    }, duration * 1000);
  };

  return (
    <div
      className="color-picker-container"
      style={{ position: 'relative' }}
      ref={containerRef}
      onClick={handleActivateWheel}
    >
      <div className="preview-header">
        <div className={`logo-container ${logoFill ? 'filled' : ''}`}>
          <img
            src={logo || DEFAULT_LOGO_URL}
            alt="Logo"
            className="logo"
            style={{
              cursor: imageAction === 1 ? 'pointer' : 'auto',
              borderRadius: roundedCorners ? '10px' : '0px',
            }}
            onClick={() => {
              if (imageAction === 1) {
                setIsImageModalOpen(true);
              }
            }}
          />
        </div>
      </div>

      <div className="question-text">
        <h3>{detectAndReplaceLinks(question)}</h3>
      </div>

      <div className="opinion-text">
      <h2
    ref={ref}
    style={{
      fontSize: `${fontSize}px`,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'center', // Center-align text if needed
    }}
  >
    {/* Check if the opinion is the default */}
    {opinion === "What's your Opinion?" ? (
      opinion
    ) : (
      <>
        <span>{opinion.split(' ')[0]}</span> {/* Main opinion */}
        <br />
        <span style={{ fontSize: `${fontSize * 0.8}px`, fontWeight: 'normal' }}>
          {opinion.split(' ').slice(1).join(' ')} {/* Confidence level */}
        </span>
      </>
    )}
  </h2>
      </div>

      <div
        className="color-wheel-wrapper"
        ref={colorWheelRef}
        style={{ position: 'relative' }}
      >
        <Wheel
          color={hsva}
          onChange={handleChange}
          width={size}
          height={size}
          style={{
            opacity: wheelActive ? 1 : alreadyVoted ? 0.5 : 0.5,
            pointerEvents: wheelActive && !isSubmitting ? 'auto' : 'none',
          }}
          onMouseUp={handleMouseUp}
          onTouchEnd={handleTouchEnd}
        />

        {showSubmit && wheelActive && !animationVisible && (
          <SubmitButton onClick={handleSubmit} />
        )}

        {animationVisible && (
          <Player
            autoplay
            loop={false}
            src={animationData}
            style={{
              height: '150px',
              width: '150px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
      </div>

      <div className="total-votes">
        <span>
          {votes.length} {votes.length === 1 ? 'Opinion' : 'Opinions'}
        </span>
      </div>

      <ColorBar
        colorPercentages={colorPercentages}
        counts={counts}
        labels={labels}
      />

      <ImageModal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        imageUrl={logo || DEFAULT_LOGO_URL}
      />
    </div>
  );
};

export default PreviewPoll;
