import React from 'react';
import logger from './logger';


const EmbedWidgetGenerator = ({ pollId, hash }) => {
  const embedCode = `<!-- OPINARI WIDGET START -->
<div onclick="(function() {
    var overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = 0;
    overlay.style.left = 0;
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0)';
    overlay.style.display = 'flex';
    overlay.style.justifyContent = 'center';
    overlay.style.alignItems = 'center';
    overlay.style.zIndex = 10000;
    overlay.style.transition = 'background-color 0.5s ease'; // Add transition for background color

    var iframe = document.createElement('iframe');
    iframe.src = '${window.location.origin}/embed/${hash}';
    iframe.style.width = '450px';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    iframe.style.borderRadius = '10px';
    iframe.style.opacity = 0; // Initially hide the iframe
    iframe.style.transition = 'opacity 0.5s ease'; // Add transition for opacity

    overlay.appendChild(iframe);
    document.body.appendChild(overlay);

    // Wait for the iframe to load, then fade in
    iframe.onload = function() {
      overlay.style.backgroundColor = 'rgb(255 255 255 / 80%)'; // Fade in the background
      iframe.style.opacity = 1; // Fade in the iframe
    };

    overlay.onclick = function() {
      document.body.removeChild(overlay);
    };
  })();" style="cursor:pointer; display: flex; align-items: center; justify-content: center; background-color: #333333; color: #ffffff; padding: 8px; border-radius: 10px; width: max-content; position: fixed; bottom: 10px; right: 10px; z-index: 1000;">
    <img src='https://opinari-images.nyc3.cdn.digitaloceanspaces.com/static/logo512.png' alt='Poll Image' style='border-radius: 50%; margin-right: 10px; width: 30px; height: 30px; animation: rotate 5s linear infinite;' />
    <span style='font-size: 14px; font-weight: 400;'>What's your opinion?</span>
    <style>
      @keyframes rotate {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
      }
    </style>
</div>
<!-- OPINARI WIDGET END -->`;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(embedCode).then(() => {
      alert('Embed widget code copied to clipboard!');
    });
  };

  return (
    <div>
      <textarea
        value={embedCode}
        readOnly
        rows="4"
        cols="50"
        className="embed-code-textarea"
      />
      <button onClick={handleCopyClick} className="tools-button">Copy Website Widget</button>
    </div>
  );
};

export default EmbedWidgetGenerator;
